import React from 'react';

function ParlamentDataNumber(props) {
  const { numberIn } = props;
  return (
    <div className='b-search__bottom'>
      <div className='container grid'>
        <div className='b-search__wrapper'>
          <h2 className='c-title c-title--s'>El Congreso, en datos</h2>
          <div className='b-search__bottom-content'>
            <dl>
              <dt className='c-title c-title--xxxxl'>{numberIn?.approvedIn}</dt>
              <dd>
                Leyes aprobadas
                <br /> esta legislatura
              </dd>
            </dl>

            <dl>
              <dt className='c-title c-title--xxxxl'>{numberIn?.inProgressIn}</dt>
              <dd>
                Leyes en
                <br /> tramitación
              </dd>
            </dl>

            <dl>
              <dt className='c-title c-title--xxxxl'>{numberIn?.rejectedIn}</dt>
              <dd>
                Leyes rechazadas
                <br /> esta legislatura
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ParlamentDataNumber);
