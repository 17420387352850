import React from 'react';
import logoNewtral from 'img/content/logo_newtral_black.svg';
import logoSponsor from 'img/content/logo-harmon-black.svg';
import logoPW from 'img/content/logo_pw.png';
import euFlag from 'img/content/Europe.jpg';

function Footer() {
  const links = [
    { name: 'Contacto', url: 'https://www.newtral.es/contacto/' },
    {
      name: 'Aviso legal',
      url: 'https://www.newtral.es/aviso-legal-y-politica-de-privacidad/',
    },
    { name: 'Quiénes somos', url: 'https://www.newtral.es/quienes-somos/' },
  ];

  const rrss = [
    { name: 'Twitter', url: 'https://twitter.com/Newtral' },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/NewtralMedia/',
    },
    { name: 'Instagram', url: 'https://www.instagram.com/newtral/?hl=es' },
  ];
  const newtralPath = 'https://www.newtral.es/';
  const sponsorPath = 'https://harmon.es/';
  const qhldPath = 'https://quehacenlosdiputados.es/';
  const euPath = 'https://www.newtral.es/parlamentia-normas-europeas/20240603/';
  return (
    <footer className='site-footer'>
      <div className='container'>
        <nav className='site-footer__menu'>
          <ul className='menu'>
            {links.map((eachOne, i) => (
              <li key={eachOne.name + '_' + i}>
                <a href={eachOne.url} target='_blank' rel='noreferrer'>
                  {eachOne.name}{' '}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <nav className='site-footer__menu site-footer__menu--bold'>
          <ul className='menu'>
            {rrss.map((eachOne, i) => (
              <li key={eachOne.name + '_' + i}>
                <a href={eachOne.url} target='_blank' rel='noreferrer'>
                  {eachOne.name}
                </a>
              </li>
            ))}
            <li>
              <a className='site-footer__menu__logo' href={newtralPath} target='_blank' rel='noreferrer'>
                <p>Una iniciativa de</p>
                <img src={logoNewtral} alt='Newtral' />
              </a>
              <a className='site-footer__menu__logo site-footer__menu__logo__sponsor' href={sponsorPath} target='_blank' rel='noreferrer'>
                <p>con el apoyo de:</p>
                <img src={logoSponsor} alt='Harmon Corporate' />
              </a>
              <a
                className='site-footer__menu__logo site-footer__menu__logo__collaboration'
                href={qhldPath}
                target='_blank'
                rel='noreferrer'
              >
                <p>con datos de:</p>
                <img src={logoPW} alt='Que hacen los diputados' />
              </a>
              <a className='site-footer__menu__logo site-footer__menu__logo__europe' href={euPath} target='_blank' rel='noreferrer'>
                <img src={euFlag} alt='Parlamento Europeo' />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
