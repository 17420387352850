import { useEffect } from 'react';

export default function useMenuSticky(props) {
  const { ref } = props;
  const menuSticky = () => {
    var scrollPos = window.scrollY;

    if (ref?.current && scrollPos > ref.current.offsetTop) {
      document.body.classList.add('menu-sticky');
    } else {
      document.body.classList.remove('menu-sticky');
    }
  };
  useEffect(() => {
    if (ref) {
      window.addEventListener('scroll', menuSticky);
    }
    return () => {
      window.removeEventListener('scroll', menuSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}
