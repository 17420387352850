import { useState, useEffect } from 'react';

export default function useStagesNavMB() {
  const [isStagesMenuOpen, setIsStagesMenuOpen] = useState(false);
  const handleCloseStagesMenu = () => {
    setIsStagesMenuOpen(false);
  };
  const handleStagesMenuClick = () => {
    setIsStagesMenuOpen(!isStagesMenuOpen);
  };
  useEffect(() => {
    let bodyElement = document.getElementsByTagName('body')[0];
    isStagesMenuOpen ? bodyElement.classList.add('menu-launched--alt') : bodyElement.classList.remove('menu-launched--alt');

    return () => {
      bodyElement.classList.remove('menu-launched--alt');
    };
  }, [isStagesMenuOpen]);

  return { isStagesMenuOpen, handleStagesMenuClick, handleCloseStagesMenu };
}
