import React from 'react';

function SearchTabs(props) {
  const { tabs, handleSelectTab, openTab } = props;
  return (
    <ul className='b-search__tabs'>
      {tabs &&
        tabs.map((eachOne, i) => (
          <li
            key={'searchTabs_' + tabs[i].type}
            className={`b-search__tab${openTab === tabs[i].type ? ' active' : ''}`}
            data-tab={tabs[i].type}
            onClick={handleSelectTab}
          >
            {tabs[i].title}
          </li>
        ))}
    </ul>
  );
}

export default SearchTabs;
