import React from 'react';
import SearchTabContent from 'components/Home/SearchTabContent';
import SearchTabs from 'elements/Home/SearchTabs';

export default function SearchSuggestions(props) {
  const { tabs, isSearching, isSuggFetched, openTab, handleSelectTab, highlightedSuggs, keyword } = props;

  return (
    <div className={`b-search__results${isSuggFetched || isSearching ? ' active' : ''}`}>
      <SearchTabs handleSelectTab={handleSelectTab} tabs={tabs} openTab={openTab} />

      {tabs &&
        tabs.map((eachOne, i) => {
          return (
            <SearchTabContent
              key={'searchtabcontent_' + tabs[i].type + '_' + i}
              array={tabs[i].array}
              type={tabs[i].type}
              handleSelectTab={handleSelectTab}
              openTab={openTab}
              isSuggFetched={isSuggFetched}
              isSearching={isSearching}
              highlightedSuggs={highlightedSuggs}
              keyword={keyword}
            />
          );
        })}
    </div>
  );
}
