import { useEffect, useState } from 'react';
import getAPI from 'services/getAPI';
import { sortOptions } from 'utils/dataArray';

export default function useInitialResults() {
  const [results, setResults] = useState([]);
  const [filterCat, setFilterCat] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  const [page, setPage] = useState(0);
  const [lastInitiative, setLastInitiative] = useState([]);
  const [sort, setSort] = useState('Últimas leyes');

  const handleSeeMore = () => {
    setIsFetched(false);
    setPage(prev => prev + 1);

    const query = {
      ...(filterCat && { category: filterCat }),
      ...(sort && { sort: sortOptions[sort] }),
      size: 9,
      page: page + 1,
    };

    getAPI({
      url: 'initiatives',
      query: query,
      typeQuery: 'obj',
    })
      .then(res => {
        var lastItem = [];
        lastItem.push(lastInitiative);
        var newResults = [...results, ...lastItem, ...res.response];
        setLastInitiative(newResults.pop());
        setResults(newResults);
        setIsFetched(true);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleCatFilter = filter => {
    setPage(0);
    if (filterCat === filter.target.innerText) {
      setFilterCat('');
    } else {
      setFilterCat(filter.target.innerText);
    }
  };

  useEffect(() => {
    const query = {
      ...(filterCat && { category: filterCat }),
      ...(sort && { sort: sortOptions[sort] }),
      size: 9,
    };
    getAPI({
      url: 'initiatives',
      query: query,
      typeQuery: 'obj',
    })
      .then(res => {
        var newResponse = res.response;
        if (newResponse.length > 8) {
          setLastInitiative(newResponse.pop());
        }
        setResults(newResponse);
        setIsFetched(true);
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      setResults([]);
      setPage(0);
      setIsFetched(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCat]);

  const handleSort = e => {
    const newSort = e.target.innerText;
    setSort(newSort);
    const query = {
      ...(filterCat && { category: filterCat }),
      ...(sort && { sort: sortOptions[newSort] }),
      size: 9,
    };
    getAPI({
      url: 'initiatives',
      query: query,
      typeQuery: 'obj',
    })
      .then(res => {
        var newResponse = res.response;
        if (newResponse.length > 8) {
          setLastInitiative(newResponse.pop());
        }
        setResults(newResponse);
        setIsFetched(true);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return {
    sort,
    results,
    isFetched,
    filterCat,
    handleSort,
    handleSeeMore,
    handleCatFilter,
  };
}
