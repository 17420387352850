import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Filter } from 'components/Results/Filter';
import MainResults from 'components/Results/MainResults';
import LegacyResults from 'components/Results/LegacyResults';

import Spinner from 'elements/Spinner';
import NoResultsMsg from 'elements/Results/NoResultsMsg';
import LiteSearchBar from 'elements/Results/LiteSearchBar';

import useBodyClass from 'hooks/useBodyClass';
import useKeyword from 'hooks/Results/useKeyword';
import useResults from 'hooks/Results/useResults';
import useShortResults from 'hooks/Home/useShortResults';

import { getURLParam, getURLFilter } from 'utils/functions';

function Results(props) {
  const { match, handleIsBurguerOpen, legacy } = props;
  const [legacyResults, setLegacyResults] = useState([]);
  const typeSearch = legacy ? 'legacyInitiatives' : 'initiatives';

  /*
   *
   * Get params from URL
   *
   */
  const { query: URLquery } = useParams();
  const URLPage = getURLParam(URLquery, 'page');
  const [page, setPage] = useState(URLPage ? URLPage : 0);

  const URLfilter = getURLFilter(URLquery);
  const [queryFilter, setQueryFilter] = useState(URLfilter ? encodeURI(URLfilter) : '');

  /*
   *
   * Hooks
   *
   */
  const { isFetched, totalResults, handleLiteSearchSubmit, URLKeyword, results, isFilterReady, isFiltering } = useResults({
    typeSearch: typeSearch,
    legacy,
    queryFilter,
    page,
    setQueryFilter,
  });
  const { isShortResultsFetched } = useShortResults({
    setLegacyResults,
    URLKeyword,
  });
  const { keyword, handleChange, handleClearSearchClick } = useKeyword({
    match: match,
  });
  useBodyClass('results', handleIsBurguerOpen);

  /*
   *
   * Results booleans
   *
   */
  const noResults = isFilterReady && totalResults <= 0 && !isFiltering && isFetched;
  const isMainResultsReady = isFilterReady && totalResults > 0 && !isFiltering && !noResults;

  return (
    <>
      <div id='content' className='page-content'>
        <LiteSearchBar
          keyword={keyword}
          handleChange={handleChange}
          handleLiteSearchSubmit={handleLiteSearchSubmit}
          handleClearSearchClick={handleClearSearchClick}
        />

        <section className={`b-list${totalResults === 0 ? ' no-results' : ''}`}>
          <Filter
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            setPage={setPage}
            legacy={legacy}
            totalResults={totalResults}
            isFetched={isFetched}
            isFiltering={isFiltering}
          />

          {legacy && (
            <div className='container'>
              <h3 className='c-title c-title--xxl c-title--mbottom'>En otras legislaturas</h3>
            </div>
          )}

          {(!isFetched || isFiltering) && !noResults && <Spinner color='white' />}

          {isMainResultsReady && (
            <MainResults
              results={results}
              legacy={legacy}
              URLKeyword={URLKeyword}
              page={page}
              totalResults={totalResults}
              setPage={setPage}
              URLPage={URLPage}
            />
          )}

          {noResults && !isFiltering && <NoResultsMsg />}
        </section>

        {!legacy && isMainResultsReady && isShortResultsFetched && legacyResults?.length > 0 && (
          <LegacyResults legacyResults={legacyResults} URLKeyword={URLKeyword} />
        )}
      </div>
    </>
  );
}

export default Results;
