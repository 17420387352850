import React, { useState, useRef, useContext } from 'react';

import FilterBlockMB from 'components/Results/FilterBlockMB';
import FilterBlockDesktop from 'components/Results/FilterBlockDesktop';

import useOpen from 'hooks/Results/useOpen';
import useFilter from 'hooks/Results/useFilter';

import { initiativesList, filterByList, processingResultList, sortByList, euDrivenList } from 'utils/dataArray';

import StaticContext from 'context/StaticContext';
import LegislatureContext from 'context/LegislatureContext';

export function Filter(props) {
  const { categoriesList } = useContext(StaticContext);
  const { legislaturesList } = useContext(LegislatureContext);
  /*
   *
   * State and hooks
   *
   */
  const { queryFilter, setQueryFilter, setPage, legacy, totalResults, isFetched, isFiltering } = props;

  const [filtersList, setFiltersList] = useState([
    { groupTitle: filterByList.orderBy, list: sortByList },
    { groupTitle: filterByList.author, list: [] },
    { groupTitle: filterByList.category, list: categoriesList },
    { groupTitle: filterByList.legislature, list: legislaturesList },
    { groupTitle: filterByList.type, list: initiativesList },
    { groupTitle: filterByList.processingResult, list: processingResultList },
    { groupTitle: filterByList.euDriven, list: euDrivenList },
  ]);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const {
    FILTER,
    MBQueueFilter,
    handleResetFilter,
    handleClickFilter,
    handleUpdateFilter,
    handleResetFilterMB,
    handleResetAllMB,
    handleHighCatClick,
    passFilterList,
  } = useFilter({ queryFilter, setQueryFilter, setPage });
  const wrapperRef = useRef(null);
  const { arrayOpen, handleOpenFilter } = useOpen({
    filtersList: filtersList,
    ref: wrapperRef,
  });

  /*
   *
   * Set filters depending on if they are legacy
   *
   */
  var filtersListFiltered;
  const legacyFilters = [filterByList.orderBy, filterByList.category];
  legacy
    ? (filtersListFiltered = filtersList.filter((eachOne, i) => {
        if (legacyFilters.includes(filtersList[i].groupTitle)) {
          return true;
        }
        return false;
      }))
    : (filtersListFiltered = filtersList.filter((eachOne, i) => filtersList[i].groupTitle !== filterByList.orderBy));

  /*
   *
   * Handlers
   *
   */
  const handleOpenFilterMB = () => {
    setIsMobileFilterOpen(true);
  };
  const handleCloseFilterMB = () => {
    setIsMobileFilterOpen(false);
  };

  return (
    <>
      <div className={`container b-list__header--wrapper${totalResults === 0 && !isFiltering && isFetched ? ' no-results' : ''}`}>
        <span className='b-list__header-count'>{totalResults} resultados</span>

        <div className='b-list__open-filters'>
          <span id='openMobileFilter' className='b-list__open-filters--open' onClick={handleOpenFilterMB}>
            Filtro
          </span>
          <div id='mobileFilter' className={`e-filter-mb${isMobileFilterOpen ? ' open' : ''}`}>
            <span id='closeMobileFilter' className='e-filter-mb__close e-back' onClick={handleCloseFilterMB}>
              Filtro
            </span>

            {filtersListFiltered.map((eachOne, i) => (
              <FilterBlockMB
                key={eachOne.groupTitle + '_mb_' + i}
                FILTER={FILTER}
                groupTitle={eachOne.groupTitle}
                list={eachOne.list}
                arrayOpen={arrayOpen}
                handleClickFilter={handleClickFilter}
                MBQueueFilter={MBQueueFilter}
                handleUpdateFilter={handleUpdateFilter}
                handleResetFilterMB={handleResetFilterMB}
                handleHighCatClick={handleHighCatClick}
                passFilterList={passFilterList}
                filtersList={filtersList}
                setFiltersList={setFiltersList}
              ></FilterBlockMB>
            ))}

            <div className='e-filter-mb__submit e-submit'>
              <button className='btn btn--primary' onClick={handleCloseFilterMB}>
                Mostrar &nbsp;<span>{totalResults}</span>
                &nbsp; resultados
              </button>
              <button className='e-reset' type='reset' onClick={handleResetAllMB}>
                Eliminar filtros
              </button>
            </div>
          </div>
        </div>

        <div className='b-list__filters' ref={wrapperRef}>
          {filtersListFiltered.map((eachOne, i) => (
            <FilterBlockDesktop
              key={eachOne.groupTitle + '_desktop_' + i}
              FILTER={FILTER}
              groupTitle={eachOne.groupTitle}
              list={eachOne.list}
              arrayOpen={arrayOpen}
              handleClickFilter={handleClickFilter}
              handleOpenFilter={handleOpenFilter}
              handleResetFilter={handleResetFilter}
              filtersList={filtersList}
              setFiltersList={setFiltersList}
            ></FilterBlockDesktop>
          ))}
        </div>
      </div>
      {totalResults <= 0 && !isFiltering && isFetched && (
        <button className='btn btn--primary b-search-lite__btn' onClick={handleResetAllMB}>
          Eliminar filtros
        </button>
      )}
    </>
  );
}
