import React from 'react';
import ModalBanner from './ModalBanner';
import ModalIcon from './ModalIcon';

export default function ModalContent(props) {
  const { title, icons, bannerTop, bannerBottom } = props;
  return (
    <div className='modal__content'>
      {title && (
        <h3 className={`modal__content-title${!icons && !bannerTop && !bannerBottom ? ' modal__content-title--mgBottom' : ''}`}>{title}</h3>
      )}
      {bannerTop &&
        bannerTop.map((eachOne, i) => (
          <ModalBanner key={eachOne.color + '_' + i} type={eachOne.color} arrowType={eachOne.arrow}>
            {eachOne.title}
          </ModalBanner>
        ))}
      {icons && (
        <ul className='modal__content-list'>
          {icons.map((eachOne, i) => (
            <ModalIcon key={eachOne.type + '_' + i} type={eachOne.type}>
              {eachOne.title}
            </ModalIcon>
          ))}
        </ul>
      )}
      {props.children && <div className='c-content'>{props.children}</div>}
      {bannerBottom &&
        bannerBottom.map((eachOne, i) => (
          <ModalBanner key={eachOne.color + '_' + i} type={eachOne.color} arrowType={eachOne.arrow}>
            {eachOne.title}
          </ModalBanner>
        ))}
    </div>
  );
}
