import React, { useContext, useEffect, useState } from 'react';

import Share from 'elements/Share';
import CardHeader from 'elements/CardHeader';
import { Link } from 'react-router-dom';
import LegislatureContext from 'context/LegislatureContext';

function SingleCardSmall(props) {
  const { path, legacy, keyword } = props;
  const { legislatureId: cardLegislatureId } = path;
  const [legislatureSlug, setLegislatureSlug] = useState('');
  const [legislatureName, setLegislatureName] = useState('');
  const { legislaturesList } = useContext(LegislatureContext);

  useEffect(() => {
    const legislature = legislaturesList?.find(x => {
      return x.id === cardLegislatureId;
    });
    if (legislature?.id === legislaturesList[0].id) {
      setLegislatureName('Legislatura actual');
    } else if (!legislature) {
      setLegislatureName('Antigua legislatura');
    } else {
      const { name } = legislature;
      const legNameNumber = name.split(' ')[0];
      const legNameName = name.split(' ')[1];
      setLegislatureName(legNameName + ' ' + legNameNumber);
    }

    const legSlugNumber = legislature?.slug.split('-')[0];
    const legSlugName = legislature?.slug.split('-')[1];
    setLegislatureSlug(legSlugName + '-' + legSlugNumber);
  }, [legislaturesList, cardLegislatureId]);

  return (
    <div className='card card-law'>
      {!legacy && <Share url={window._env_.REACT_APP_HOMEPAGE + '/' + legislatureSlug + '/ley/' + path.slug} />}

      {legacy ? (
        <a href={path.url} target='_blank' rel='noreferrer'>
          <CardHeader path={path} legacy={legacy} keyword={keyword} legislatureName={legislatureName} />
        </a>
      ) : (
        <Link
          to={{
            pathname:
              path?.storyId && path?.storyStatus === 'published'
                ? `/${legislatureSlug}/resumen/${path.slug}`
                : `/${legislatureSlug}/ley/${path.slug}`,
            fromParlamentia: true,
          }}
        >
          <CardHeader path={path} legacy={legacy} keyword={keyword} legislatureName={legislatureName} />
        </Link>
      )}
    </div>
  );
}

export default React.memo(SingleCardSmall);
