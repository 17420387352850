import React, { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';

export default function YoutubeWrapper(props) {
  const { info, isSelected } = props;
  const regexp = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/;
  const youtubeId = info?.url?.match(regexp) ? info?.url?.match(regexp)[3] : '';

  const [videoEnd, setVideoEnd] = useState(null);
  const iframeRef = useRef(null);
  const start = info?.start;
  const end = info?.end;

  const handleOnClick = e => {
    e.stopPropagation();
    iframeRef.current.getInternalPlayer().seekTo(start);
    setVideoEnd(false);
  };
  const handleOnReady = e => {
    if (e.target.getDuration() < start) {
      e.target.stopVideo();
    }
  };
  useEffect(() => {
    if (!isSelected && iframeRef?.current) iframeRef.current.getInternalPlayer().pauseVideo();
  }, [isSelected, iframeRef]);

  return (
    <div className={`story__wrapper ${videoEnd ? 'ended' : ''}`} onClick={videoEnd ? e => handleOnClick(e) : null}>
      <YouTube
        videoId={youtubeId}
        id={youtubeId}
        className='story__video'
        containerClassName='story__wrapper--inside'
        onReady={handleOnReady}
        opts={{
          playerVars: {
            controls: 0,
            start: start,
            end: end,
            modestbranding: 1,
            autohide: 1,
            rel: 0,
            showinfo: 0,
            fs: 0,
          },
        }}
        onEnd={() => setVideoEnd(true)}
        ref={iframeRef}
      />
    </div>
  );
}
