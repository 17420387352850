import React from 'react';
import TitleNav from 'elements/SingleInitiative/TitleNav';

export default function StagesMenu(props) {
  const { activeTitleNav, handleNavClick, singleIn = [{ state: '' }] } = props;
  const stages = singleIn.stages || [{ state: '' }];
  const stagesList = stages
    .filter(eachOne => {
      const title = eachOne.state;
      return title !== '';
    })
    .map((eachOne, i) => {
      const title = eachOne.state;
      return (
        <TitleNav
          key={stages[i].state + '_' + singleIn.id + '_' + i}
          navId={title + i}
          title={title}
          activeTitleNav={activeTitleNav}
          handleNavClick={handleNavClick}
        />
      );
    });

  return (
    <div className='b-single__nav'>
      <nav>
        <ul>
          <TitleNav navId='Resumen' title='Resumen' activeTitleNav={activeTitleNav} handleNavClick={handleNavClick} />

          {stagesList}
        </ul>
      </nav>
    </div>
  );
}
