import React from 'react';
import ProgressBar from './ProgressBar';
import Story from './Story';

import icoShare from 'img/backgrounds/ico_share.svg';
import storiesArrow from 'img/content/stories-arrow.svg';
import closeLogo from 'img/content/ico_close.svg';

import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { NavLink } from 'react-router-dom';
import ShareTab from './ShareTab';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import DraggableCard from './draggableCard';

const StoryContainer = React.forwardRef((props, ref) => {
  const { slides, selected, i, path, setSelected, sharedSlide, setSharedSlide, relatedInfo, legislatureSlug, pathBack } = props;
  const location = useLocation();
  const history = useHistory();
  const data = slides[i];
  const isSelected = selected === i;
  const { leftButtonRef, rightButtonRef } = ref;
  const isIframe = window.location.pathname.includes('/embed/');

  const handleGoBackClick = () => {
    pathBack && pathBack !== '' ? history.push(pathBack) : history.goBack();
  };

  return (
    <li
      className={`story-container ${!isSelected ? 'inactive' : 'selected'} ${Math.abs(selected - i) > 1 ? 'none' : ''} ${
        sharedSlide !== null && sharedSlide !== i ? 'non-shared' : ''
      } `}
    >
      <div className='story-container__bg'></div>
      <div className={`story-container__header ${!isSelected ? 'hidden' : 'selected'}`}>
        <div className='story-container__subt'>
          <ProgressBar slides={slides} selected={selected} />
          {path?.initiativeType ? <p>{path.initiativeType}</p> : ''}
        </div>
        <div className='story-container__share' onClick={() => setSharedSlide(sharedSlide => (sharedSlide === i ? null : i))}>
          <img src={icoShare} alt='Compartir' />
        </div>
        {isIframe ? null : location?.fromParlamentia ? (
          <a className='stories__nav--close' onClick={handleGoBackClick}>
            <img src={closeLogo} alt='Botón para cerrar historias' />
          </a>
        ) : (
          <NavLink exact to='/buscador-de-leyes' className='stories__nav--close'>
            <img src={closeLogo} alt='Botón para cerrar historias' />
          </NavLink>
        )}
      </div>

      <Story info={data} isSelected={isSelected} isIframe={isIframe} />
      <div className='story-container__buttons'>
        {isSelected && (
          <>
            <button
              className={`story-container__arrows--left ${!selected ? 'hidden' : ''}`}
              ref={leftButtonRef}
              onClick={() => {
                setSelected(prevSelected => prevSelected - 1);
                setSharedSlide(null);
              }}
            >
              <img src={storiesArrow} alt='Flecha izquierda de historias' />
            </button>
            <button
              className={`story-container__arrows--right ${selected === slides?.length - 1 ? 'hidden' : ''} `}
              ref={rightButtonRef}
              onClick={() => {
                setSelected(prevSelected => prevSelected + 1);
                setSharedSlide(null);
              }}
            >
              <img src={storiesArrow} alt='Flecha derecha de historias' />
            </button>
          </>
        )}
        <button
          className={`story-container__btn btn btn--primary ${!isSelected ? 'hidden' : ''}`}
          onClick={() =>
            isIframe
              ? window.open(window._env_.REACT_APP_HOMEPAGE + '/' + legislatureSlug + '/ley/' + path.slug)
              : history.push('/' + legislatureSlug + '/ley/' + path.slug)
          }
        >
          Ver detalles
        </button>
      </div>
      <SwipeableBottomSheet open={sharedSlide === i} onChange={() => setSharedSlide(sharedSlide => (sharedSlide === i ? null : i))}>
        <DraggableCard>
          <ShareTab lawData={path} relatedInfo={relatedInfo} legislatureSlug={legislatureSlug} />
        </DraggableCard>
      </SwipeableBottomSheet>
    </li>
  );
});

StoryContainer.displayName = 'StoryContainer';
export default StoryContainer;
