import { useEffect } from 'react';
import getAPI from 'services/getAPI';
export default function useSlug(props) {
  /*
   *
   * State
   *
   */
  const { setSingleIn, setIsFetched, urlID } = props;

  /*
   *
   * Call API
   *
   */
  useEffect(() => {
    const url =
      window.location.pathname.includes('/ley/') ||
      window.location.pathname.includes('/resumen/') ||
      window.location.pathname.includes('/embed/')
        ? 'singleInitiative'
        : 'singleLegacyInitiative';

    // During SSR we set the initiative on "window.__fetchedInitiative__" to avoid calling the API in the first render
    if (
      url === 'singleInitiative' &&
      typeof window !== 'undefined' &&
      (window.__fetchedInitiative__?.slug === urlID || window.__fetchedInitiative__?._id === urlID)
    ) {
      setSingleIn(window.__fetchedInitiative__);
      setIsFetched(true);

      return;
    }

    getAPI({ url: url, id: urlID, typeQuery: 'id' })
      .then(res => {
        setSingleIn(res._source);
      })
      .then(() => {
        setIsFetched(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
