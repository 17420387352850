import React from 'react';
import SingleCardSmall from 'components/SingleCardSmall';
import SeeMore from 'elements/SeeMore';

export default function LegacyResults(props) {
  const { legacyResults, URLKeyword } = props;
  return (
    <section className='b-list b-list__legacy'>
      <div className='container'>
        <h3 className='c-title c-title--xxxl'>En otras legislaturas</h3>
      </div>
      <div className='container container--grid'>
        {legacyResults.map((eachOne, i) => {
          return <SingleCardSmall key={'cardlaw_' + legacyResults[i]._source.id} path={legacyResults[i]._source} legacy />;
        })}
      </div>
      <SeeMore
        text='Ver todas'
        url={`/leyes-pasadas/text=${URLKeyword}`}
        tag='anchor'
        handler={() => {
          window.scrollTo(0, 0);
        }}
      />
    </section>
  );
}
