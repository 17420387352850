import React, { useMemo } from 'react';
import InsideHTML from './InsideHTML';
import StoriesAmendments from './StoriesAmendments';
import StoriesVoting from './StoriesVoting';
import YoutubeWrapper from './YoutubeWrapper';

function getAmendmentsData(info, isIframe) {
  if (!isIframe) {
    return { amendments: info?.data.amendments, amendmentCount: info?.data.amendmentCount };
  }
  try {
    const data = JSON.parse(info.data);
    const amendmentData = Array.isArray(data) ? data[0] : data;
    return { amendments: amendmentData.amendments, amendmentCount: amendmentData.amendmentCount };
  } catch (err) {
    return undefined;
  }
}

export default function Story(props) {
  const { info, isSelected, isIframe } = props;
  let componentToLoad = null;
  if (info?.kind === 'amendments') {
    const data = getAmendmentsData(info, isIframe);
    if (data) {
      componentToLoad = <StoriesAmendments info={data.amendments} total={data.amendmentCount} />;
    }
  } else if (info?.kind === 'voting') {
    const votingData = info?.voting ? info : info?.data[0];
    componentToLoad = <StoriesVoting info={votingData} />;
  }

  const src = useMemo(() => {
    if (info?.kind === 'image' && (info?.image?.url || info?.file?.url)) {
      return info?.file?.url ? URL.createObjectURL(info?.file?.url) : info?.image.url;
    }
  }, [info]);

  return (
    <div className='story'>
      {info?.kind === 'video' && <YoutubeWrapper info={info} isSelected={isSelected} />}
      {(info?.image?.url || info?.file?.url) && <img className='story__image' src={src} />}
      {info?.preTitle && <h3 className='story__pretitle'>{info?.preTitle} </h3>}
      <h1 className={`story__title${info?.title?.length > 12 && info?.kind !== 'amendments' ? '--long' : ''}`}>
        <span>{info?.kind === 'amendments' ? 'Enmiendas' : info?.title}</span>
      </h1>
      {info?.summary ? (
        <p className='story__summary'>
          <span>{info?.summary}</span>
        </p>
      ) : info?.description ? (
        <p className='story__summary story__summary__voting'>
          <span>{info?.description}</span>
        </p>
      ) : (
        ''
      )}
      {componentToLoad ?? componentToLoad}
      {info?.categories && (
        <div className='story__categories'>
          {info?.categories
            ?.sort((a, b) => a.name.length - b.name.length)
            ?.map(category => (
              <div className='story__category' key={category.id}>
                <img src={category.image.url} alt='Categoria de noticia' />
                <span>{category.name}</span>
              </div>
            ))}
        </div>
      )}
      {info?.iframe && <InsideHTML html={info?.iframe} />}
    </div>
  );
}
