import { useEffect, useState } from 'react';
import getAPI from 'services/getAPI';

export default function useStories(urlId, preview) {
  const [data, setData] = useState(null);
  const [isStoryFetched, setIsStoryFetched] = useState(null);

  useEffect(() => {
    if (urlId && !preview)
      getAPI({
        url: 'initiativeStoriesId',
        typeQuery: 'id',
        id: urlId,
      })
        .then(res => {
          setData(res?._source);
          setIsStoryFetched(res ? true : false);
        })
        .catch(err => {
          console.log(err);
          setIsStoryFetched(false);
        });
    return () => setIsStoryFetched(false);
  }, [urlId, preview]);

  return [data, isStoryFetched, setData, setIsStoryFetched];
}
