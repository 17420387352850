import React from 'react';
import FormatDate from 'elements/FormatDate';
import AuthorImg from './AuthorImg';

export default function Author(props) {
  const { path, pointer, handleClick, subtitle } = props;
  const numberAuthors = path?.authors?.length;
  return (
    <div className={`e-author${pointer ? ' pointer' : ''}`} onClick={handleClick}>
      {Array.isArray(path.authors) &&
        numberAuthors > 0 &&
        path.authors.map((eachOne, i) => {
          if (i === 4) {
            return (
              <>
                <i title='Ver todos' className='e-author__portrait e-author__portrait--more'>
                  <span>+{numberAuthors - i}</span>
                </i>
              </>
            );
          }
          if (i >= 5) {
            return false;
          }
          return (
            <AuthorImg
              key={'Author_' + path.authors[i]?.name + '_' + i}
              src={path.authors[i]?.image?.url || null}
              name={path.authors[i]?.name || null}
            />
          );
        })}

      <dl className='e-author__name'>
        {path.authors && Array.isArray(path.authors) && numberAuthors > 0 && (
          <dt>{numberAuthors > 1 ? 'Autores' : path.authors[0].name}</dt>
        )}
        {subtitle && <dd>{subtitle}</dd>}
        {path.updated && <FormatDate date={path.updated} />}
      </dl>
    </div>
  );
}
