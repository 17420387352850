import React, { useState } from 'react';
import useCategories from 'hooks/useCategories';

const Context = React.createContext({ categoriesList: [{}] });

export const StaticContext = ({ children }) => {
  const [values, setValues] = useState({ categoriesList: [{}] });

  const { ready } = useCategories({ setValues: setValues });

  if (!ready) {
    return <></>;
  }
  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export default Context;
