import React from 'react';

function FormatDate(props) {
  const { date } = props;
  const newDate = new Date(date);
  const formatDate = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();

  return <dd>Actualizada a {formatDate}</dd>;
}

export default FormatDate;
