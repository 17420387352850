import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Home from 'pages/Home';
import About from 'pages/About';
import Results from 'pages/Results';
import SingleInitiative from 'pages/SingleInitiative';

import NotFound from 'components/404';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ModalLaws from 'components/ModalLaws';

import { SEO } from 'elements/SEO';

import useNumberInitiatives from 'hooks/useNumberInitiatives';

import './App.css';

import { StaticContext } from 'context/StaticContext';
import { LegislatureContext } from 'context/LegislatureContext';
import StoriesWrapper from 'components/Stories/StoriesWrapper';
import MainModal from 'components/MainModal';

function App() {
  /*
   *
   * Burguer
   *
   */
  const [isBurguerOpen, setIsBurguerOpen] = useState(false);
  const handleBurguerClick = e => {
    e.preventDefault();
    setIsBurguerOpen(!isBurguerOpen);
    let bodyElement = document.getElementsByTagName('body')[0];
    if (isBurguerOpen) {
      bodyElement.classList.remove('menu-launched');
    } else {
      bodyElement.classList.add('menu-launched');
    }
    e.stopPropagation();
  };
  const handleIsBurguerOpen = isOpen => {
    setIsBurguerOpen(isOpen);
  };

  /*
   *
   * Modals
   *
   */
  const [activeModal, setActiveModal] = useState('');
  const handleOpenModal = e => {
    e.preventDefault();
    const active = e.currentTarget.getAttribute('data-modal-law');
    setActiveModal(active);
  };
  const handleCloseModal = () => {
    setActiveModal('');
  };

  const [activeMainModal, setActiveMainModal] = useState('');
  useEffect(() => {
    const activeMainModal = window._env_.ACTIVE_MAIN_MODAL;
    if (!activeMainModal) {
      return;
    }
    const mainModalShown = sessionStorage.getItem('mainModalShown');
    if (!mainModalShown) {
      setActiveMainModal('mainModal');
    }
  }, []);
  const handleCloseMainModal = () => {
    setActiveMainModal('');
    sessionStorage.setItem('mainModalShown', true);
  };

  /*
   *
   * Twitter
   *
   */
  window.twttr = (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://platform.twitter.com/widgets.js';
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };

    return t;
  })(document, 'script', 'twitter-wjs');

  /*
   *
   * Get summary of initiatives
   *
   */
  const numberIn = useNumberInitiatives();

  return (
    <>
      <SEO
        title='Parlamentia - Newtral'
        description='El Congreso en datos. Parlamentia es la primera plataforma que te explica cómo se hacen las leyes en España, desde que llegan al Congreso hasta que se publican en el BOE.'
        url={window.location.href}
        creator=''
      />
      <Header
        handleBurguerClick={handleBurguerClick}
        handleIsBurguerOpen={handleIsBurguerOpen}
        handleOpenModal={handleOpenModal}
        isBurguerOpen={isBurguerOpen}
      />
      <StaticContext>
        <LegislatureContext>
          <Switch>
            <Route
              exact
              path='/buscador-de-leyes'
              render={() => {
                return (
                  <>
                    <Home handleOpenModal={handleOpenModal} handleIsBurguerOpen={handleIsBurguerOpen} numberIn={numberIn} />
                    <Footer />
                  </>
                );
              }}
            />
            <Route
              path={['/:legislatureId/ley/:id']}
              render={routeProps => {
                return (
                  <>
                    <SingleInitiative handleOpenModal={handleOpenModal} handleIsBurguerOpen={handleIsBurguerOpen} {...routeProps} />
                    <Footer />
                  </>
                );
              }}
            />
            <Route
              exact
              path='/que-es-parlamentia'
              render={() => {
                return (
                  <>
                    <About handleOpenModal={handleOpenModal} handleIsBurguerOpen={handleIsBurguerOpen} />
                    <Footer />
                  </>
                );
              }}
            />
            <Route
              path='/leyes/:query'
              render={routeProps => {
                return (
                  <>
                    <Results handleIsBurguerOpen={handleIsBurguerOpen} {...routeProps} />
                    <Footer />
                  </>
                );
              }}
            />
            <Route
              path='/leyes'
              render={routeProps => {
                return (
                  <>
                    <Results handleIsBurguerOpen={handleIsBurguerOpen} {...routeProps} />
                    <Footer />
                  </>
                );
              }}
            />
            <Route
              path='/leyes-pasadas/:query'
              render={routeProps => {
                return (
                  <>
                    <Results handleIsBurguerOpen={handleIsBurguerOpen} legacy {...routeProps} />
                    <Footer />
                  </>
                );
              }}
            />
            <Route
              path='/:legislatureId/resumen/:id'
              render={routeProps => {
                return (
                  <>
                    <StoriesWrapper {...routeProps} />
                  </>
                );
              }}
            />
            <Route
              path='/:legislatureId/embed/:id'
              render={routeProps => {
                return (
                  <>
                    <StoriesWrapper {...routeProps} />
                  </>
                );
              }}
            />
            <Route component={NotFound} />
            <Route
              render={() => {
                return (
                  <>
                    <Home handleOpenModal={handleOpenModal} handleIsBurguerOpen={handleIsBurguerOpen} />
                    <Footer />
                  </>
                );
              }}
            />
          </Switch>

          <ModalLaws activeModal={activeModal} handleCloseModal={handleCloseModal} handleIsBurguerOpen={handleIsBurguerOpen} />
          <MainModal
            activeMainModal={activeMainModal}
            handleCloseMainModal={handleCloseMainModal}
            handleIsBurguerOpen={handleIsBurguerOpen}
          />
        </LegislatureContext>
      </StaticContext>
    </>
  );
}

export default withRouter(App);
