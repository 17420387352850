import { useState, useEffect } from 'react';
import getAPI from 'services/getAPI';

export default function useLegislatures(props) {
  const { setValues } = props;
  const [ready, setReady] = useState(false);
  useEffect(() => {
    getAPI({
      url: 'legislatures',
      typeQuery: 'noQuery',
    })
      .then(res => {
        const legislatures = res.response.map(el => {
          return {
            id: el._source.id,
            name: el._source.name,
            slug: el._source.slug,
          };
        });
        setValues({ legislaturesList: legislatures });
      })
      .then(() => setReady(true))
      .catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ready };
}
