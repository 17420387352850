import ModalButton from 'elements/Header/ModalButton';
import React from 'react';
import { modalButtons } from 'utils/dataArray';

function SingleCardInfo(props) {
  const { handleOpenModal } = props;

  const fourLinks = ['Proyecto de Ley', 'Proposición de Ley', 'Real Decreto Ley', 'Reforma del Estatuto de Autonomía'];
  const modalButtonsFiltered = modalButtons.filter(eachOne => fourLinks.includes(eachOne.title));
  return (
    <div className='card card-info'>
      <h2 className='c-title c-title--xxxl'>El proceso</h2>
      <div className='card-info__text'>
        <p>Te explicamos cómo se aprueban las leyes, desde que se registran en las Cortes hasta que se publican en el BOE</p>
      </div>
      <ul className='card-info__links'>
        {modalButtonsFiltered.map(eachOne => (
          <li key={'singleCardInfoButton_' + eachOne.title}>
            <ModalButton data={eachOne.data} title={eachOne.title} handleOpenModal={handleOpenModal} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SingleCardInfo;
