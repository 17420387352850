import AuthorImg from 'elements/AuthorImg';
import React from 'react';
export default function VoteLegend(props) {
  const { parlGroup, number } = props;
  return (
    <li>
      <div className='e-author'>
        <AuthorImg src={parlGroup.image?.url} name={parlGroup.name} />

        <span className='e-author__all'> {parlGroup.name}</span>
      </div>
      <div className='b-vote__legend--wrapper'>
        <span>
          <strong>{number}</strong>
        </span>
      </div>
    </li>
  );
}
