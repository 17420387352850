import React from 'react';

export default function AmendmentsItem(props) {
  const { count, name, color } = props;

  const squares = Array.from(Array(count).keys());
  return (
    <div className='b-squares__inner'>
      <div className='b-squares__inner-header'>
        <strong>{count}</strong>
        <span> {name}</span>
      </div>
      <div className='b-squares__squares'>
        {squares.map((_, i) => (
          <span key={`square_${i}+${name}`} style={{ backgroundColor: color }}></span>
        ))}
      </div>
    </div>
  );
}
