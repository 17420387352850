import React from 'react';

export default function AboutPanel(props) {
  const { src, alt = '', textPosition, title, black } = props;

  const image = (
    <figure>
      <img src={src ? src : ''} alt={alt} />
    </figure>
  );

  const text = (
    <>
      <h3 className='c-title c-title--xxxl'>{title}</h3>
      <div className='c-content'>{props.children}</div>
    </>
  );

  return (
    <section className={`b-starred ${textPosition === 'right' ? 'b-starred--alt' : ''} ${black ? 'b-starred--black' : ''}`}>
      <div className={`container ${textPosition === 'top' || textPosition === 'bottom' ? '' : 'grid'}`}>
        <div className='b-starred__left'> {textPosition === 'left' || textPosition === 'top' ? text : image}</div>
        <div className='b-starred__right'>{textPosition === 'left' || textPosition === 'top' ? image : text}</div>
      </div>
    </section>
  );
}
