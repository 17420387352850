import React from 'react';

export default function Spinner(props) {
  const { color } = props;

  return (
    <div className='spinner'>
      <div className={`lds-ring ${color}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>Cargando</span>
    </div>
  );
}
