import React, { useState } from 'react';

import Spinner from 'elements/Spinner';
import HomeResults from 'elements/Home/HomeResults';
import { sortOptions } from 'utils/dataArray';

function InitialResults(props) {
  const { results, handleSeeMore, handleOpenModal, isFetched, sort, handleSort } = props;
  const [isSortOpen, setIsSortOpen] = useState(false);
  const handleOpenSort = () => {
    setIsSortOpen(!isSortOpen);
  };
  return (
    <section className='b-list'>
      <div className='container b-list__header'>
        <div className={`e-dropdown e-dropdown__laws e-dropdown__open${isSortOpen ? ' open' : ''}`} onClick={handleOpenSort}>
          <span className='e-dropdown__title'>{sort}</span>
          <ul className={`e-dropdown__content${isSortOpen ? ' active' : ''}`}>
            {Object.keys(sortOptions).map(eachOne => (
              <li key={eachOne}>
                <button
                  onClick={e => {
                    handleSort(e);
                    setIsSortOpen(false);
                  }}
                >
                  {eachOne}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isFetched ? (
        <HomeResults results={results} handleOpenModal={handleOpenModal} handleSeeMore={handleSeeMore} />
      ) : (
        <Spinner color='white' />
      )}
    </section>
  );
}

export default InitialResults;
