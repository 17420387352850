import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import getAPI from 'services/getAPI';
import { getURLParam } from 'utils/functions';
import { objToQueryString } from 'utils/functions';
export default function useResults(props) {
  /*
   *
   * Props
   *
   */

  const { typeSearch, legacy, queryFilter = '', page = 0 } = props;

  /*
   *
   * States
   *
   */

  const history = useHistory();
  const { query: URLquery } = useParams();
  const [results, setResults] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [URLKeyword, setURLKeyword] = useState(getURLParam(URLquery, 'text'));
  const [isFilterReady, setIsFilterReady] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);

  /*
   *
   * Fetch on URLKeyword change
   *
   */
  useEffect(() => {
    const query = {
      ...(URLKeyword && { text: URLKeyword }),
      ...(page && { page: page }),
      size: '9',
    };
    const finalQuery = objToQueryString(query) + queryFilter;

    getAPI({
      url: typeSearch,
      query: finalQuery,
      typeQuery: 'string',
    })
      .then(res => {
        setTotalResults(res.total);
        setResults(res.response);
      })
      .then(() => {
        setIsFetched(true);
        setIsFilterReady(true);
        setIsFiltering(false);

        const actualURL = window.location.href;

        if (actualURL.includes('/leyes-pasadas')) {
          window.history.replaceState(null, '', `/leyes-pasadas/${finalQuery}`);
        } else if (actualURL.includes('/leyes')) {
          window.history.replaceState(null, '', `/leyes/${finalQuery}`);
        }
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      setResults([]);
      setIsFetched(false);
    };
  }, [URLKeyword, typeSearch, queryFilter, page]);

  /*
   *
   * Handlers
   *
   */
  const handleLiteSearchSubmit = (e, keyword) => {
    e.preventDefault();
    const url = legacy ? '/leyes-pasadas' : '/leyes';
    const encKeyword = encodeURIComponent(keyword);
    history.push(`${url}/text=${encKeyword}`);
    setURLKeyword(keyword);
  };

  return {
    isFetched,
    totalResults,
    URLKeyword,
    handleLiteSearchSubmit,
    results,
    isFilterReady,
    isFiltering,
  };
}
