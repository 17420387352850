import React from 'react';
import ico_quien from 'img/fake/ico_quien.svg';
import ico_cuando from 'img/fake/ico_cuando.svg';
import ico_donde from 'img/fake/ico_donde.svg';
import ico_que from 'img/fake/ico_que.svg';
import ico_votacion from 'img/fake/ico_votacion.svg';

function ModalIcon(props) {
  const { type } = props;
  const ICON_OPTIONS = {
    cuando: { src: ico_cuando, alt: 'Cuándo' },
    donde: { src: ico_donde, alt: 'Dónde' },
    que: { src: ico_que, alt: 'Qué' },
    quien: { src: ico_quien, alt: 'Quién' },
    votacion: { src: ico_votacion, alt: 'Votación' },
  };
  const ICON_DEFAULT = { src: '', alt: '' };

  const icon = ICON_OPTIONS[type] || ICON_DEFAULT;
  if (props.children) {
    return (
      <li title={icon.alt}>
        <i>
          <img src={icon.src} alt={icon.alt} />
        </i>
        {props.children}
      </li>
    );
  } else {
    return (
      <i>
        <img src={icon.src} alt={icon.alt} />
      </i>
    );
  }
}
export default ModalIcon;
