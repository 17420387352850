import React from 'react';

const DraggableCard = ({ children }) => {
  return (
    <div id='draggable-card'>
      <section className='children'>{children}</section>
    </div>
  );
};

export default DraggableCard;
