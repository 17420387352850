import useSlug from 'hooks/SingleInitiative/useSlug';
import { useQuery } from 'hooks/useQuery';
import useStories from 'hooks/useStories';
import React, { useEffect, useState } from 'react';
import Stories from './Stories';

export default function StoriesWrapper(props) {
  const { pathBack } = props;
  const urlID = props.match.params.id;
  const legislatureId = props.match.params.legislatureId;
  const query = useQuery();
  const isPreview = query.get('preview');
  const [singleIn, setSingleIn] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  const [isPreviewReady, setIsPreviewReady] = useState(false);
  const [legislatureSlug, setLegislatureSlug] = useState('');

  useEffect(() => {
    const legislatureSlug = legislatureId.endsWith('-legislatura')
      ? legislatureId.split('-')[0] + '-' + legislatureId.split('-')[1]
      : legislatureId;
    setLegislatureSlug(legislatureSlug);
  }, [legislatureId]);

  useSlug({
    setSingleIn,
    setIsFetched,
    urlID: urlID,
  });
  const [source, isStoryFetched, setData, setIsStoryFetched] = useStories(singleIn?.storyId, isPreview);

  useEffect(() => {
    if (isPreview) {
      const messageEvent = e => {
        if (e.origin.indexOf('localhost:8000') !== -1 || e.origin.indexOf('newtral.es') !== -1) {
          setData({ slides: e.data });
          setIsStoryFetched(true);
          setIsPreviewReady(true);
        }
      };
      window.parent.postMessage('loaded', '*');
      window.addEventListener('message', messageEvent);
      return () => {
        window.removeEventListener('message', messageEvent);
      };
    }
  }, [isPreview, setIsStoryFetched, setData]);

  return (isFetched && isStoryFetched) || isPreviewReady ? (
    <Stories
      urlID={urlID}
      legislatureSlug={legislatureSlug}
      isPreview={isPreview}
      isFetched={isFetched}
      isStoryFetched={isStoryFetched}
      source={source}
      singleIn={singleIn}
      pathBack={pathBack}
    />
  ) : null;
}
