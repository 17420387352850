import React from 'react';
import arrowDown from 'img/content/arrow-down.svg';
import cornerRightDown from 'img/content/corner-right-down.svg';
import ModalIcon from 'elements/Modals/ModalIcon';

export default function ModalBanner(props) {
  const { type, arrowType } = props;
  const BANNER_OPTIONS = {
    green: { color: '#0F7C34', class: 'success' },
    red: { color: '#F54242', class: 'wrong' },
  };
  const ARROW_OPTIONS = {
    down: arrowDown,
    cornerDown: cornerRightDown,
  };
  const BANNER_DEFAULT = '';

  const banner = BANNER_OPTIONS[type] || BANNER_DEFAULT;

  if (type === 'grey') {
    return (
      <div className='modal__notice modal__notice--info'>
        <ModalIcon type='que' />
        <span>{props.children}</span>
      </div>
    );
  }
  return (
    <div className={`modal__notice modal__notice--${banner.class}`}>
      <i>
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.00033 2.33301C5.38149 2.33301 4.78799 2.57884 4.35041 3.01643C3.91282 3.45401 3.66699 4.0475 3.66699 4.66634V15.333C3.66699 15.9518 3.91282 16.5453 4.35041 16.9829C4.78799 17.4205 5.38149 17.6663 6.00033 17.6663H14.0003C14.6192 17.6663 15.2127 17.4205 15.6502 16.9829C16.0878 16.5453 16.3337 15.9518 16.3337 15.333V7.33301C16.3337 7.06779 16.2283 6.81344 16.0408 6.6259L12.0408 2.6259C11.8532 2.43836 11.5989 2.33301 11.3337 2.33301H6.00033ZM6 5.99969H10V7.99969H6V5.99969ZM6 8.99969V10.9997H14V8.99969H6ZM6 11.9997V13.9997H14V11.9997H6Z'
            fill={banner.color}
          />
        </svg>
      </i>
      <span>{props.children}</span>
      {type === 'green' && arrowType && (
        <i className='modal__notice__step'>
          <img src={ARROW_OPTIONS[arrowType]} alt='Flecha' />
        </i>
      )}
    </div>
  );
}
