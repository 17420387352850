import React from 'react';

import InitialResults from 'components/Home/InitialResults';
import FilterCategories from 'elements/Home/FilterCategories';

import useInitialResults from 'hooks/Home/useInitialResults';

function Search(props) {
  const { handleOpenModal } = props;
  const { results, handleCatFilter, handleSeeMore, handleSort, isFetched, filterCat, sort } = useInitialResults();
  return (
    <>
      <FilterCategories handleCatFilter={handleCatFilter} filterCat={filterCat} />

      <InitialResults
        sort={sort}
        results={results}
        isFetched={isFetched}
        handleSort={handleSort}
        handleSeeMore={handleSeeMore}
        handleOpenModal={handleOpenModal}
      />
    </>
  );
}

export default Search;
