import { useState, useEffect } from 'react';
import getAPI from 'services/getAPI';

export default function useCategories(props) {
  const { setValues } = props;
  const [ready, setReady] = useState(false);
  useEffect(() => {
    getAPI({
      url: 'initiativeCategories',
      typeQuery: 'noQuery',
    })
      .then(res => {
        const cats = [];
        res.response.forEach(el => {
          const newObj = {
            name: el._source.name,
            src: el._source.image?.url,
            shortName: el._source.shortName,
          };
          cats.push(newObj);
        });
        setValues({ categoriesList: cats });
      })
      .then(() => setReady(true))
      .catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ready };
}
