import React, { useRef } from 'react';
import StoriesAmendmentsItem from './StoriesAmendmentsItem';

export default function StoriesAmendments(props) {
  const { info, total } = props;
  const elementRef = useRef(null);

  return (
    <>
      <div className={`story__amendments__container `}>
        <div className={`story__amendments`} ref={elementRef}>
          <div className='b-squares'>
            <div className='b-squares__header'>
              <h3 className='b-squares__title'>{total}</h3>
              <p className='b-squares__subtitle'>En total</p>
            </div>

            {info &&
              info
                .sort((a, b) => b.amendmentCount - a.amendmentCount)
                .map(eachOne => {
                  return (
                    <StoriesAmendmentsItem
                      key={eachOne.parliamentaryGroups[0].name}
                      count={eachOne.amendmentCount}
                      name={eachOne.parliamentaryGroups[0].name}
                      color={eachOne.parliamentaryGroups[0].color ? eachOne.parliamentaryGroups[0].color : `rgb(${0},${0},${0})`}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
}
