import React from 'react';

import SearchBar from 'components/Home/SearchBar';
import ParlamentDataNumber from 'elements/Home/ParlamentDataNumber';

export default function SearchHeader(props) {
  const { numberIn } = props;
  return (
    <section className='b-search'>
      <div className='b-search__top'>
        <div className='container grid'>
          <div className='b-search__wrapper'>
            <h1 className='c-title c-title--home--xxxl c-title--centered'>Parlamentia, así se hacen las leyes en España</h1>
            <SearchBar />
          </div>
        </div>
      </div>
      <ParlamentDataNumber numberIn={numberIn} />
    </section>
  );
}
