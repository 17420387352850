import React from 'react';
import SingleCardSmall from 'components/SingleCardSmall';
import SingleCardInfo from 'elements/Home/SingleCardInfo';
import SeeMore from 'elements/SeeMore';
import NoResultsMsg from 'elements/Results/NoResultsMsg';

export default function HomeResults(props) {
  const { results, handleOpenModal, handleSeeMore } = props;
  return (
    <>
      {results.length <= 0 && <NoResultsMsg />}

      {results.length > 0 && (
        <div className='container container--grid'>
          {results &&
            results !== 'undefined' &&
            results.map((eachOne, i) => {
              return <SingleCardSmall key={'cardlaw_' + results[i]._source.id} path={results[i]._source} />;
            })}

          {results.length >= 5 && <SingleCardInfo handleOpenModal={handleOpenModal} />}
        </div>
      )}

      {results.length >= 8 && <SeeMore handler={handleSeeMore} text='Ver más' tag='button' />}
    </>
  );
}
