import React from 'react';

import Voting from 'elements/SingleInitiative/Voting';
import Document from 'elements/SingleInitiative/Document';
import Amendments from 'elements/SingleInitiative/Amendments';

import useDays from 'hooks/SingleInitiative/useDays';
import useTweets from 'hooks/SingleInitiative/useTweets';
import Description from 'elements/SingleInitiative/Description';

// eslint-disable-next-line react/display-name
const SingleCard = React.forwardRef((props, ref) => {
  const { info, title, path, navId } = props;
  const documents = info.documentsOverride?.length > 0 ? info.documentsOverride : info.documents;
  const days = useDays({
    info: info,
    path: path,
    title: title,
  });

  useTweets({ tweets: info.tweets });

  return (
    <div id={navId} className='b-single__inner' ref={ref}>
      <div className='card card-single'>
        {days}

        {info.description && <Description text={info.description} />}

        {info.voting &&
          info.voting?.voting?.length > 0 &&
          info.voting.voting.map((eachOne, i) => (
            <Voting key={'voting_' + i} info={eachOne} parliamentaryGroups={info.voting.parliamentaryGroups} />
          ))}

        {info.amendments && info.amendments.length > 0 && <Amendments info={info.amendments} total={info.amendmentCount} />}

        {info.tweets?.length > 0 && (
          <div className='card-single__inner'>
            <h2 className='c-title c-title--xl'>¿Qué piensa el resto?</h2>
            {info.tweets.map((eachOne, i) => {
              const id = String(eachOne.substring(eachOne.lastIndexOf('/') + 1));
              return <blockquote id={`${id}`} key={`tweet_${id}_${i}`}></blockquote>;
            })}
          </div>
        )}

        {documents && documents.length > 0 && (
          <div className='card__bottom'>
            {documents.map(eachOne => (
              <Document key={'document_' + eachOne.name} url={eachOne.s3Url} date={eachOne.date} title={eachOne.description} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
export default React.memo(SingleCard);
