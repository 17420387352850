import PageNumber from 'elements/Results/PageNumber';
import React, { useEffect } from 'react';

export default function usePage(props) {
  const { URLKeyword, totalResults, setPage, page, URLPage } = props;
  const pageNumbers = [];

  /*
   *
   * Reset page number on change Keyword
   *
   */
  useEffect(() => {
    setPage(URLPage ? URLPage : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URLKeyword]);

  /*
   *
   * Handlers
   *
   */
  const handlePageClick = e => {
    setPage(parseInt(e.currentTarget.innerText) - 1);
    window.scrollTo(0, 0);
  };
  const handlePrevPage = () => {
    setPage(prevPage => parseInt(prevPage) - 1);
    window.scrollTo(0, 0);
  };
  const handleNextPage = () => {
    setPage(prevPage => parseInt(prevPage) + 1);
    window.scrollTo(0, 0);
  };

  /*
   *
   * Set pagination numbers
   *
   */
  for (let i = 1; i <= Math.ceil(totalResults / 9); i++) {
    pageNumbers.push(i);
  }
  const lastNumber = pageNumbers.slice(-1)[0];
  const renderPageNumbers = pageNumbers.map((number, i) => {
    if (number === lastNumber) {
      return false;
    }
    if (number - 1 === page) {
      return <PageNumber key={number + '_' + i} number={number} handleClick={handlePageClick} active />;
    }
    if (number + 4 > lastNumber && page >= lastNumber - 4) {
      return <PageNumber key={number + '_' + i} number={number} handleClick={handlePageClick} />;
    }
    if (number < page || number > page + 3 || page === number) {
      return false;
    }
    return <PageNumber key={number + '_' + i} number={number} handleClick={handlePageClick} />;
  });

  return {
    page,
    pageNumbers,
    renderPageNumbers,
    handlePageClick,
    handlePrevPage,
    handleNextPage,
  };
}
