import { useState, useEffect } from 'react';
import getAPI from 'services/getAPI';

function useNumberInitiatives() {
  const [numberIn, setNumberIn] = useState({});
  useEffect(() => {
    getAPI({
      url: 'initiativesSummary',
      typeQuery: 'noQuery',
    })
      .then(res => {
        const path = res.response[0]._source;

        setNumberIn({
          ...setNumberIn,
          inProgressIn: path.inProgressLegislativeInitiatives,
          approvedIn: path.approvedLegislativeInitiatives,
          rejectedIn: path.rejectedLegislativeInitiatives,
          initiativesByCategory: path.initiativesByCategory,
        });
      })
      .catch(err => console.log(err));
    return () => {};
  }, []);

  return numberIn;
}

export default useNumberInitiatives;
