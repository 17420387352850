import StaticContext from 'context/StaticContext';
import IconCat from 'elements/IconCat';
import useDragSlider from 'hooks/useDragSlider';
import React, { useRef, useContext } from 'react';

export default function FilterCategories(props) {
  const { handleCatFilter, filterCat } = props;

  const filterOptions = useContext(StaticContext).categoriesList;

  const slider = useRef(null);
  const { start, move, end } = useDragSlider({ ref: slider });

  return (
    <section className='b-filters'>
      <div className='container'>
        <ul ref={slider} onMouseDown={e => start(e)} onMouseMove={e => move(e)} onMouseLeave={end} onMouseUp={end}>
          {filterOptions.map((eachOne, i) => {
            const name = eachOne.name;
            return (
              <li key={name + '_' + i}>
                <button className={`e-filter-btn${filterCat === name ? ' active' : ''}`} onClick={name => handleCatFilter(name)}>
                  <IconCat name={name} />
                  <span>{name}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
