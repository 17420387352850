import React from 'react';

export default function ModalButton(props) {
  const { title, data, handleOpenModal } = props;
  return (
    <button data-modal-law={data} onClick={handleOpenModal}>
      {title}
    </button>
  );
}
