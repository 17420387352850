import React, { useContext } from 'react';
import IconCat from 'elements/IconCat';
import { filterByList } from 'utils/dataArray';

import { listIncludes } from 'utils/functions';

import StaticContext from 'context/StaticContext';

export default function CategoryButton(props) {
  const { FILTER, handleHighCatClick, name } = props;
  const { categoriesList } = useContext(StaticContext);
  const index = categoriesList.findIndex(item => item.name === name);

  let shortName;
  if (index >= 0) {
    shortName = categoriesList[index].shortName;
  }
  return (
    <li key={'highcat_' + name} className={listIncludes(FILTER[filterByList.category], name) ? 'active' : ''}>
      <i>
        <IconCat name={name} />
      </i>
      <span>{typeof shortName === 'string' ? shortName : name}</span>
      <label className='input--checkbox'>
        <input
          type='checkbox'
          value={name}
          data-value={name}
          onChange={handleHighCatClick}
          checked={listIncludes(FILTER[filterByList.category], name)}
        />
      </label>
    </li>
  );
}
