import { useEffect } from 'react';

const useBodyClass = (name, handleIsBurguerOpen, secName, condition) => {
  useEffect(() => {
    let bodyElement = document.body;
    bodyElement.classList.add(name);

    if (condition) {
      bodyElement.classList.add(secName);
    } else if (!condition) {
      bodyElement.classList.remove(secName);
    }

    return () => {
      bodyElement.classList.remove(name);
      bodyElement.classList.remove(secName);
      bodyElement.classList.remove('menu-launched');

      handleIsBurguerOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, condition]);
};

export default useBodyClass;
