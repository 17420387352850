import React from 'react';
import Status from 'elements/Status';
import IconCat from 'elements/IconCat';
import Author from 'elements/Author';
import parse from 'html-react-parser';
import EuFlag from 'img/content/europa.png';

export default function CardHeader(props) {
  const { path, legacy, keyword, legislatureName } = props;

  const getSearchWords = keyword => {
    if (keyword.startsWith('"') && keyword.endsWith('"')) {
      return [keyword.replaceAll('"', '').toLowerCase()];
    }
    return keyword.split(' ').map(el => el.toLowerCase());
  };

  const getTitleHtml = (title, words) => {
    if (words.length > 1) {
      const wordArr = title.split(' ');
      title = [];
      wordArr.forEach(function (titleWord) {
        if (words.includes(titleWord.toLowerCase())) {
          titleWord = '<span class="yellow">' + titleWord + '</span>';
        }
        title.push(titleWord);
      });
      title = title.join(' ');
    } else {
      const strictWords = words[0].toLowerCase();
      if (title.includes(strictWords)) {
        title = title.replaceAll(strictWords, '<span class="yellow">' + strictWords + '</span>');
      }
    }
    title = parse(title);
    return title;
  };

  const title = () => {
    const isAlias = path.titleAlias && path.titleAlias !== '';
    let title = isAlias ? path.titleAlias : path.title;

    if (keyword !== undefined) {
      const words = getSearchWords(keyword);
      if (words.length) {
        title = getTitleHtml(title, words);
      }
    }

    return title;
  };

  return (
    <>
      <div className='card-law__header'>
        {!legacy && <Status info={path.processingResult} />}

        {legacy && path.year && <h2 className='card-law__title card-law__title--legacy c-title c-title--xxxxl'>{path.year}</h2>}
      </div>
      <div className='card-law__title__info--small'>
        {path.euDriven && (
          <div className='card-law__title__eu--small'>
            <img src={EuFlag} alt='Ley impulsada por Europa' />
          </div>
        )}
        <div className='card-law__legislature'>{legislatureName}</div>
      </div>
      <div className='card-law__title'>
        <h2 className={`card-law__title${path.titleAlias ? ' c-title c-title--xxxxl' : ''}`}>{title()}</h2>
      </div>

      <div className='card-law__info'>
        {!legacy && <Author path={path} />}

        {Array.isArray(path.categories) && path.categories.length > 0 && (
          <ul className='e-cats'>
            <li className='e-cat'>
              <i>
                <IconCat name={path.categories[0].name} />
              </i>
              {path.categories[0].name}
            </li>
            {path.categories.length > 1 && <li className='e-cat e-cat--more'>+{path.categories.length - 1}</li>}
          </ul>
        )}
      </div>
      <div className='card__share'></div>
    </>
  );
}
