import React from 'react';
import Share from 'elements/Share';
import Author from 'elements/Author';
import Status from 'elements/Status';
import IconCat from 'elements/IconCat';
import Document from 'elements/SingleInitiative/Document';
import EuFlag from 'img/content/europa.png';
import { parseText } from 'utils/functions';

const Summary = React.forwardRef((props, ref) => {
  const { singleIn, handleOpenAuthor, legislatureName } = props;
  const mostRecentDocument = singleIn.mostRecentDocumentOverride ? singleIn.mostRecentDocumentOverride : singleIn.mostRecentDocument;
  return (
    <div id='Resumen' ref={ref} className='b-single__inner'>
      <div className='card card-law card-single'>
        <div className='card-single__inner'>
          <div className='card-law__header'>
            <Status info={singleIn.processingResult} />
          </div>
          <div className='card-law__title'>
            <div className='card-law__title__info'>
              {singleIn.euDriven && (
                <div className='card-law__title__eu'>
                  <img src={EuFlag} alt='Ley impulsada por Europa' />
                </div>
              )}
              <p className='card-law__title__reference'>Ref: {singleIn.reference}</p>
              <p className='card-law__title__legislature'>
                <strong>{legislatureName}</strong>
              </p>
            </div>
            {singleIn.titleAlias ? (
              <h1 className='card-law__title c-title c-title--xxxxl'>{singleIn.titleAlias}</h1>
            ) : (
              <h1 className='card-law__title c-title c-title--xl'>{singleIn.title}</h1>
            )}
          </div>

          <div className='card__share'>
            <span className='card__share-open'></span>
          </div>

          {singleIn.titleAlias && (
            <div className='card-law__text'>
              <p> {singleIn.title}</p>
            </div>
          )}

          <div className='card-law__info'>
            <Author path={singleIn} pointer={singleIn?.authors?.length > 1 ? true : false} handleClick={handleOpenAuthor} />

            {!Array.isArray(singleIn.categories) || singleIn.categories.length <= 0 ? (
              <ul className='e-cats'></ul>
            ) : (
              <ul className='e-cats'>
                {singleIn.categories.map((singleCat, i) => {
                  return (
                    <li className='e-cat' key={singleIn.categories[i].name + '_' + singleIn.id}>
                      <i>
                        <IconCat name={singleIn.categories[i].name} />
                      </i>
                      {singleIn.categories[i].name}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <Share url={window.location.href} />
        </div>

        {mostRecentDocument && (
          <div className='card__bottom'>
            <Document
              url={mostRecentDocument.s3Url}
              date={mostRecentDocument.date}
              title={`Documento más actual: ${mostRecentDocument.description}`}
            />
          </div>
        )}

        {singleIn.euDriven && singleIn.euDrivenInfo && (
          <div className='ue'>
            <div className='ue__box'>
              <div className='ue__box__img'>
                <img src={EuFlag} alt='Ley impulsada por Europa' />
              </div>
              <span className='ue__box__title'>
                <strong>Ley impulsada por Europa</strong>
              </span>
            </div>
            <span className='ue__text'>{parseText(singleIn.euDrivenInfo)}</span>
          </div>
        )}
      </div>
    </div>
  );
});
Summary.displayName = 'Summary';
export default Summary;
