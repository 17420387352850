import React, { useEffect, useRef } from 'react';
import MainModalHeader from 'elements/Modals/MainModalHeader';
import ModalContent from 'elements/Modals/ModalContent';
import { fadeIn, fadeOut } from 'utils/functions';
import { mainModal } from 'utils/dataArray';

export default function MainModal(props) {
  const { activeMainModal, handleCloseMainModal } = props;
  const modal = useRef(null);
  const modalNames = mainModal.map(eachOne => eachOne.data);

  useEffect(() => {
    modalNames.includes(activeMainModal) ? fadeIn(modal.current) : fadeOut(modal.current);
  }, [activeMainModal, modalNames]);

  return (
    <div className='modal modal--laws' id='mainModal' ref={modal}>
      <MainModalHeader
        handleCloseMainModal={handleCloseMainModal}
        activeMainModal={activeMainModal}
        activeMainModalValue='mainModal'
        title='Los primeros pasos de la nueva legislatura'
      >
        <ModalContent>
          <p>
            La XV Legislatura ya ha arrancado en el Congreso de los Diputados y, poco a poco, se va retomando la actividad parlamentaria
            habitual. No obstante, el Gobierno está en funciones, por lo que no puede presentar <strong>ningún proyecto de ley</strong>
          </p>
          <p>
            En cambio, los distintos grupos parlamentarios sí registran sus respectivas proposiciones con el objetivo de completar su
            recorrido hasta su publicación en el BOE.
          </p>
          <p>
            En Parlamentia, vamos a ir ofreciendo toda la información actualizada para que puedas hacer el seguimiento de cada iniciativa,
            que explicaremos con <strong>resúmenes, datos y contexto.</strong>
          </p>
          <p>
            También puedes consultar <strong>las más de 650 normas</strong> tramitadas en la legislatura anterior (2019 - 2023).
          </p>
        </ModalContent>
      </MainModalHeader>
    </div>
  );
}
