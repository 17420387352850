import React from 'react';

export default function ModalInfo(props) {
  const { title, titleGenre, data, handleOpenModal } = props;
  return (
    <div className='e-info'>
      <div className='e-info__wrapper'>
        <p className='e-info__question'>
          ¿Cómo se aprueba
          {titleGenre === 'f' ? ' una' : ' un'}
          <br />
          {title}?
        </p>
        <span className='modalLawsOpen e-info__answer' data-modal-law={data} onClick={handleOpenModal}>
          Saber más
        </span>
      </div>
    </div>
  );
}
