import React from 'react';
import VoteLegend from './VoteLegend';

function compare(a, b) {
  if (a.number > b.number) {
    return -1;
  }
  if (a.number < b.number) {
    return 1;
  }
  return 0;
}
function findGroup(id, arr) {
  for (let i = 0; i < arr.length; i++) {
    // FIXME: Homogenize the ids: from DB it comes with underscore but at some point we override it removing the underscore
    if (arr[i].id === id || arr[i]._id === id) {
      return arr[i];
    }
  }
}
export default function VoteList(props) {
  const { votes, name, info, parliamentaryGroups } = props;
  const VOTE_OPTIONS = {
    yes: 'A favor',
    no: 'En contra',
    abstention: 'Abstención',
    noVote: 'No vota',
  };

  if (votes?.length > 0) {
    return (
      <ul key={`legend_{i}_${name}`} className='b-vote__legend b-vote__legend--alt'>
        <div className='b-vote__legend--header-wrapper'>
          <span className='b-vote__legend--header'>
            <span className={`b-vote__legend--title ${name}`}>
              <strong>{VOTE_OPTIONS[name]}</strong>
            </span>
            <span>
              <strong>{info.voteCount[name]}</strong>
            </span>
          </span>
        </div>
        {votes.sort(compare).map((eachOne, i) => {
          const parlGroup = findGroup(eachOne.id, parliamentaryGroups);
          return <VoteLegend key={`parlGroup_${i}_${eachOne.number}_${parlGroup}`} parlGroup={parlGroup} number={eachOne.number} />;
        })}
      </ul>
    );
  } else {
    return false;
  }
}
