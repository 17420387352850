import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from 'img/content/logo_parlamentia.svg';
import logoNewtral from 'img/content/logo_newtral.svg';
import logoSponsor from 'img/content/logo-harmon-white.svg';
import { modalButtons } from 'utils/dataArray';

import DropdownLaws from 'elements/Header/DropdownLaws';
import ModalButton from 'elements/Header/ModalButton';

import useMenuSticky from 'hooks/Header/useMenuSticky';

function Header(props) {
  const { isBurguerOpen, handleBurguerClick, handleIsBurguerOpen, handleOpenModal } = props;
  const newtralPath = 'https://www.newtral.es/';
  const sponsorPath = 'https://harmon.es/';
  /*
   *
   * States and hooks
   *
   */
  const siteHeader = useRef(null);
  useMenuSticky({
    ref: siteHeader,
  });

  /*
   *
   * Handlers
   *
   */
  const handleNavLinkClick = e => {
    window.scrollTo(0, 0);
    if (e.currentTarget.classList.contains('active')) {
      let bodyElement = document.getElementsByTagName('body')[0];
      bodyElement.classList.remove('menu-launched');
      handleIsBurguerOpen(false);
    }
  };

  /*
   *
   * Open mobile menu
   *
   */
  const menuMB = useRef(null);
  const subMenu = useRef(null);
  const [isMenuMBOpen, setIsMenuMBOpen] = useState(false);
  const handleMenuMBClick = () => {
    setIsMenuMBOpen(!isMenuMBOpen);
  };

  return (
    <>
      <header className='site-header' ref={siteHeader}>
        <div className='container'>
          <button className={`e-burger__wrapper${isBurguerOpen ? ' btn-close' : ''}`} onClick={handleBurguerClick}>
            <span className='e-burger'></span>
          </button>
          <p className='e-logo'>
            <NavLink exact to='/buscador-de-leyes' onClick={handleNavLinkClick}>
              <img src={logo} alt='Parlamentia' />
            </NavLink>
          </p>
          <div className='site-header__wrapper'>
            <NavLink to='/que-es-parlamentia' className='site-header__link' onClick={handleNavLinkClick}>
              Qué es Parlamentia
            </NavLink>

            <DropdownLaws handleOpenModal={handleOpenModal} />
          </div>
          <div className='site-header__wrapper site-header__wrapper__logo'>
            <a className='site-header__logo' href={newtralPath} target='_blank' rel='noreferrer'>
              <span>Una iniciativa de </span>
              <img src={logoNewtral} alt='Newtral' />
            </a>
            <a className='site-header__logo site-header__logo__sponsor' href={sponsorPath} target='_blank' rel='noreferrer'>
              <span className='site-header__logo__sponsor'> con</span>
              <img src={logoSponsor} alt='Harmon Corporate' />
            </a>
          </div>
        </div>
      </header>
      <div className='mobile-nav' id='mobile-nav'>
        <nav className='mobile-nav__menu'>
          <ul>
            <li>
              <NavLink to='/que-es-parlamentia' onClick={handleNavLinkClick}>
                Qué es Parlamentia
              </NavLink>
            </li>
            <li className='menu-item-has-children'>
              <button ref={menuMB} className={isMenuMBOpen ? 'open' : ''} onClick={handleMenuMBClick}>
                Cómo se aprueban las leyes
              </button>
              <ul ref={subMenu} className={`sub-menu${isMenuMBOpen ? ' active' : ''}`}>
                {modalButtons.map(eachOne => (
                  <li key={'modalButtonHeader_' + eachOne.title}>
                    <ModalButton title={eachOne.title} data={eachOne.data} handleOpenModal={handleOpenModal} />
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
        <div className='mobile-nav__logo'>
          <a className='mobile-nav__logo__newtral' href={newtralPath} target='_blank' rel='noreferrer'>
            <span>Una iniciativa de </span>
            <p></p>
            <img src={logoNewtral} alt='Newtral' />
          </a>
          <span>
            con{' '}
            <a className='mobile-nav__logo__sponsor' href={sponsorPath} target='_blank' rel='noreferrer'>
              <img src={logoSponsor} alt='Harmon Corporate' />
            </a>{' '}
          </span>
        </div>
      </div>
    </>
  );
}

export default Header;
