import React, { useState } from 'react';
import { parseText } from 'utils/functions';

export default function EditorSummary(props) {
  const { singleIn } = props;
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const handleOpenSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  var content = parseText(singleIn.summary);

  return (
    <div className='b-single__inner'>
      <div className='card card-single card-summary'>
        <div className='card-single__inner'>
          <div className={`card-summary__wrapper${isSummaryOpen ? ' open' : ''}`} data-content>
            <h2 className='c-title c-title--xl'>Resumen</h2>
            <div className='card-summary__inner'>
              <div className='c-content parsed-text'>{content}</div>
            </div>
          </div>
          <div className={`card-summary__open${isSummaryOpen ? ' open' : ''}`} data-open onClick={handleOpenSummary}></div>
        </div>
      </div>
    </div>
  );
}
