import React from 'react';
import usePage from 'hooks/Results/usePage';
import PageNumber from './PageNumber';

export default function Pagination(props) {
  const { URLKeyword, totalResults, page, setPage, URLPage } = props;
  const { renderPageNumbers, pageNumbers, handlePageClick, handlePrevPage, handleNextPage } = usePage({
    URLKeyword,
    totalResults,
    page,
    setPage,
    URLPage,
  });

  const lastNumber = pageNumbers.slice(-1)[0];
  return (
    <div className='container'>
      <nav className='page-numbers' aria-label='navigation'>
        <ul className='pagination'>
          {page > 0 && <PageNumber handleClick={handlePrevPage} type='prev' />}
          {renderPageNumbers}
          {page + 4 < lastNumber && <PageNumber handleClick={handlePrevPage} type='dots' />}
          <PageNumber number={lastNumber} handleClick={handlePageClick} active={page + 1 === lastNumber ? true : false} />
          {page !== lastNumber - 1 && <PageNumber handleClick={handleNextPage} type='next' />}
        </ul>
      </nav>
    </div>
  );
}
