import { useState } from 'react';
import { getURLParam } from 'utils/functions';
export default function useKeyword(props) {
  const { match } = props;

  const handleClearSearchClick = () => {
    setKeyword('');
    document.getElementById('liteSearchBarInput').focus();
  };
  const handleChange = e => {
    setKeyword(e.target.value);
  };

  const decodedKeyword = decodeURIComponent(getURLParam(match.params.query, 'text'));
  const [keyword, setKeyword] = useState(
    decodedKeyword && decodedKeyword !== 'null' && decodedKeyword !== 'undefined' ? decodedKeyword : '',
  );
  return { keyword, handleChange, handleClearSearchClick };
}
