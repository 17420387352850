import React from 'react';
import { fadeOut } from 'utils/functions';
import AuthorImg from '../AuthorImg';

const ModalAuthor = React.forwardRef((props, ref) => {
  const { authors } = props;
  const authorsLength = authors?.length;
  const handleCloseAuthor = () => {
    fadeOut(ref.current);
  };

  return (
    <div className='modal modal--authors' ref={ref}>
      <div className='modal__inner active'>
        <div className='modal__wrapper'>
          <span className='modalClose' onClick={handleCloseAuthor}></span>
          <div className='modal__header'>
            <h2 className='c-title c-title--xxxl'>Autores</h2>
            <p className='modal__subtitle'> {authorsLength} autores en total</p>
          </div>
          {authors &&
            authors.map((eachOne, i) => (
              <div key={eachOne} className='modal__content'>
                <div className='e-author'>
                  <AuthorImg key={authors[i].name + '_' + i} src={authors[i].image?.url || null} name={authors[i].name || null} />
                  <dl className='e-author__name'>
                    <dt>{authors[i].name || null}</dt>
                  </dl>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
});

ModalAuthor.displayName = 'ModalAuthor';
export default ModalAuthor;
