import axios from 'axios';
import { objToQueryString } from 'utils/functions';

const backend = window._env_.REACT_APP_BACKEND;

const getURL = props => {
  const { url, query, id, typeQuery } = props;
  const URL_OPT = {
    initiativeCategories: `${backend}/initiative-categories/`,
    initiatives: `${backend}/initiatives?`,
    legacyInitiatives: `${backend}/legacy-initiatives?`,
    suggestions: `${backend}/suggestions-initiatives?`,
    suggestionsInitiativesHighlights: `${backend}/suggestions-initiatives/highlights?`,
    singleInitiative: `${backend}/initiatives/`,
    singleLegacyInitiative: `${backend}/legacy-initiatives/`,
    initiativesSummary: `${backend}/insights/initiatives/summary`,
    durationAverage: `${backend}/insights/initiatives/stages/duration/average`,
    initiativeAuthors: `${backend}/initiative-authors?`,
    initiativeAuthorsId: `${backend}/initiative-authors/`,
    initiativeStories: `${backend}/initiative-stories?`,
    initiativeStoriesId: `${backend}/initiative-stories/`,
    legislatures: `${backend}/legislatures/`,
  };
  const URL_QUERY = {
    string: URL_OPT[url] + query,
    obj: URL_OPT[url] + objToQueryString(query),
    id: URL_OPT[url] + id,
    noQuery: URL_OPT[url],
  };

  return URL_QUERY[typeQuery];
};

export default function getAPI(props) {
  const { url, query, id, typeQuery, retries = 3, timeout = 3500 } = props;
  const APIUrl = getURL({
    url: url,
    query: query,
    id: id,
    typeQuery: typeQuery,
  });
  async function APIcall(APIUrl, timeout) {
    const APIresponse = await axios.get(APIUrl, {
      timeout: timeout,
    });
    const status = await APIresponse.status;
    const data = await APIresponse.data;
    return { status, data };
  }

  const timerAxios = async APIUrl => {
    const attempts = Array.from(Array(retries).keys());
    for (const attempt of attempts) {
      try {
        const incrementedTimeout = timeout + attempt * 1000;
        const { data, status } = await APIcall(APIUrl, incrementedTimeout);
        if (status === 200) {
          return data;
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const axiosRequest = timerAxios(APIUrl);

  return axiosRequest;
}
