import React from 'react';
import parlamentia from 'img/fake/parlamentia.svg';
import Author from 'elements/Author';
import { parseText } from 'utils/functions';

export default function Description(props) {
  const { text } = props;
  const imgData = {
    authors: [
      {
        name: 'Parlamentia',
        image: {
          url: parlamentia,
        },
      },
    ],
  };
  const content = parseText(text);
  return (
    <div className='card-single__inner'>
      <Author subtitle='Ampliación' path={imgData} />
      <div className='c-content parsed-text'>{content}</div>
    </div>
  );
}
