import { processingResultList } from 'utils/dataArray';
import tramitacion from 'img/fake/tramitacion.svg';
import React from 'react';

export default function Status(props) {
  const { info } = props;

  const RESULTS_DEFAULT = 'En tramitación';
  const ICON_DEFAULT = tramitacion;

  const index = processingResultList.findIndex(el => el.name === info);

  const img = processingResultList[index]?.src || ICON_DEFAULT;
  const text = processingResultList[index]?.name || RESULTS_DEFAULT;

  return (
    <>
      <i className='card-law__status--ico'>
        <img src={img} alt={text} />
      </i>
      <small className='card-law__status'>{text}</small>
    </>
  );
}
