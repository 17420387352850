import { useState, useEffect } from 'react';
import getAPI from 'services/getAPI';

function getTopThree(obj) {
  if (obj) {
    var topThree = [];
    for (const key in obj) {
      for (let i = 0; i < obj[key].length; i++) {
        const element = obj[key][i];
        topThree.push(element);
      }
    }
  }

  topThree = topThree.slice(0, 3);
  return topThree;
}
export default function useSuggestions(props) {
  const { keyword, openTab } = props;
  const [suggestion, setSuggestion] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSuggFetched, setIsSuggFetched] = useState(false);
  const [highlightedSuggs, setHighlightedSuggs] = useState(null);
  const [topThree, setTopThree] = useState([]);

  /*
   *
   *  Fetch highlighted suggestions
   *
   */
  useEffect(() => {
    getAPI({
      url: 'suggestionsInitiativesHighlights',
      query: { size: 3 },
      typeQuery: 'obj',
    })
      .then(res => {
        setHighlightedSuggs(res.response);
      })
      // .then(setIsSuggFetched(true))
      .catch(err => {
        console.log(err);
      });

    return () => {
      setHighlightedSuggs(null);
      setIsSuggFetched(false);
    };
  }, []);

  /*
   *
   *  Fetch
   *
   */
  useEffect(() => {
    if (keyword.length > 2) {
      setIsSearching(true);
      setIsSuggFetched(true);
      const fetchData = async () => {
        if (openTab !== 'all') {
          await getAPI({
            url: 'suggestions',
            query: { text: keyword, filter: openTab, sort: 'score', size: 3 },
            typeQuery: 'obj',
          })
            .then(res => {
              setSuggestion(res[openTab]);
            })
            .catch(err => {
              console.log(err);
            });
          setIsSearching(false);
        } else {
          await getAPI({
            url: 'suggestions',
            query: { text: keyword, size: 3 },
            typeQuery: 'obj',
          })
            .then(res => {
              setTopThree(
                getTopThree({
                  category: res.category,
                  author: res.author,
                  title: res.title,
                }),
              );
            })

            .catch(err => {
              console.log(err);
            });
          setIsSearching(false);
        }
      };

      var timer = setTimeout(() => {
        fetchData();
      }, 300);
    } else if (keyword.length <= 2) {
      setSuggestion(highlightedSuggs);
      setIsSuggFetched(true);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, openTab]);

  return {
    suggestion,
    highlightedSuggs,
    topThree,
    isSuggFetched,
    isSearching,
  };
}
