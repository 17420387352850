import React, { useCallback, useEffect, useState } from 'react';

import useAuthorSearch from 'hooks/Results/useAuthorSearch';

import FilterList from './FilterList';
import SelectedFilterName from 'elements/Results/SelectedFilterName';
import CategoryButton from 'elements/Results/CategoryButton';
import { filterByList, sortByList } from 'utils/dataArray';

export default function FilterBlockMB(props) {
  const {
    list,
    handleClickFilter,
    MBQueueFilter,
    groupTitle,
    handleResetFilterMB,
    FILTER,
    handleUpdateFilter,
    handleHighCatClick,
    passFilterList,
    filtersList,
    setFiltersList,
  } = props;
  const [isInnerOpen, setIsInnerOpen] = useState(false);
  const { isSearching, keyword, handleAuthorInputChange } = useAuthorSearch({
    filtersList,
    setFiltersList,
    FILTER,
  });
  const highlightCats = ['Economía', 'Hacienda', 'Transición Ecológica', 'Interior y Defensa', 'Sanidad y Consumo', 'Educación'];
  const filterLength = FILTER[groupTitle]?.length;

  /*
   * Handlers
   */
  const handleOpenInnerFilterMB = groupTitle => {
    // initialFilterByScore();
    setIsInnerOpen(true);
    passFilterList(groupTitle);
  };
  const handleCloseInnerFilterMB = (e, groupTitle) => {
    setIsInnerOpen(false);
    handleResetFilterMB(e, groupTitle);
  };
  const handleSaveFilterMB = (e, groupTitle, handleUpdateFilter) => {
    setIsInnerOpen(false);
    handleUpdateFilter(e, groupTitle);
  };

  const initialFilterByScore = useCallback(() => {
    if (FILTER[filterByList.orderBy].length < 1) {
      FILTER[filterByList.orderBy] = [{ name: sortByList[0].searchName }];
      const score = document.querySelector(`#mobileFilter [data-value="${sortByList[0].searchName}"]`);
      if (score) {
        const event = new Event('click');
        score.dispatchEvent(event);
        handleUpdateFilter(event, filterByList.orderBy);
        handleClickFilter(event, filterByList.orderBy);
      }
    }
  }, [FILTER, handleClickFilter, handleUpdateFilter]);

  const getNameByFilterGroup = eachOne => {
    if (groupTitle === filterByList.legislature) {
      return eachOne.id;
    }
    if (groupTitle === filterByList.euDriven) {
      return eachOne.data;
    }
    return eachOne.name;
  };

  useEffect(() => {
    initialFilterByScore();
  }, [initialFilterByScore]);

  return (
    <div className='e-filter-mb__filter'>
      <span
        className={`e-filter-mb__filter-open${groupTitle === filterByList.category ? ' cats' : ''}`}
        onClick={() => handleOpenInnerFilterMB(groupTitle)}
      >
        {groupTitle !== filterByList.category && (
          <span className='e-filter-mb__filter-open--text'>
            {groupTitle}
            {filterLength > 0 && (
              <span className='e-filter-mb__filter-subtitle'>
                {FILTER[groupTitle].map((eachOne, i) => (
                  <SelectedFilterName
                    key={eachOne.name + '_' + groupTitle + '_' + i}
                    i={i}
                    name={getNameByFilterGroup(eachOne)}
                    groupTitle={groupTitle}
                    filterLength={filterLength}
                    FILTER={FILTER}
                  />
                ))}
              </span>
            )}
          </span>
        )}

        {groupTitle === filterByList.category && (
          <>
            {groupTitle}
            <span>
              Ver todas
              <i>
                <img src='img/backgrounds/mobile_menu.svg' alt='' />
              </i>
            </span>
          </>
        )}
      </span>

      {groupTitle === filterByList.category && (
        <ul className={`e-filter-mb__cats${filterLength > 0 ? ' active' : ''}`}>
          {highlightCats.map((eachOne, i) => (
            <CategoryButton
              key={eachOne + '_highlightedCategory_' + i}
              handleHighCatClick={handleHighCatClick}
              FILTER={FILTER}
              name={eachOne}
            />
          ))}
        </ul>
      )}

      {groupTitle === filterByList.category && FILTER[groupTitle]?.length > 0 && (
        <span className='e-filter-mb__filter-subtitle '>
          {FILTER[groupTitle].map((eachOne, i) => (
            <SelectedFilterName key={eachOne.name + '_selectedFilter_' + i} i={i} name={eachOne.name} filterLength={filterLength} />
          ))}
        </span>
      )}

      <div className={`e-filter${isInnerOpen ? ' open' : ''}`}>
        <span className='e-filter__back e-back' onClick={e => handleCloseInnerFilterMB(e, groupTitle)}>
          {groupTitle}
        </span>

        <FilterList
          list={list}
          FILTER={FILTER}
          keyword={keyword}
          groupTitle={groupTitle}
          isSearching={isSearching}
          filtersList={filtersList}
          setFiltersList={setFiltersList}
          handleClickFilter={handleClickFilter}
          handleAuthorInputChange={handleAuthorInputChange}
          MBQueueFilter={MBQueueFilter}
          isInnerOpen={isInnerOpen}
          type='mobile'
        />

        <div className='e-filter__submit'>
          <button className='btn btn--primary' onClick={e => handleSaveFilterMB(e, groupTitle, handleUpdateFilter)}>
            Guardar
          </button>
          <button className='e-reset' type='reset' onClick={e => handleResetFilterMB(e, groupTitle)}>
            Restablecer
          </button>
        </div>
      </div>
    </div>
  );
}

FilterBlockMB.defaultProps = {
  list: [],
  groupTitle: '',
  arrayOpen: [],
  FILTER: {},
  MBQueueFilter: [],
};
