import React from 'react';
import { Link } from 'react-router-dom';

export default function SeeMore(props) {
  const { handler, text, tag, url } = props;

  return (
    <div className='container'>
      <div className='btn-wrapper btn-wrapper--center'>
        {tag === 'button' && (
          <button className='btn btn--primary' onClick={handler}>
            {text}
          </button>
        )}
        {tag === 'anchor' && (
          <Link to={url} className='btn btn--primary' onClick={handler}>
            {text}
          </Link>
        )}
      </div>
    </div>
  );
}
