import { useState, useEffect } from 'react';
function reverseDate(date) {
  if (date === 'undefined' || date === null) {
    return 0;
  }
  return date.split('-').reverse().join('/');
}
function getDiffDate(start, end) {
  if (start === 'undefined' || start === null) {
    return false;
  }
  if (end === null || end === 'undefined') {
    var today = new Date();
    var dateSplit = start.split('-');
    var dateObj = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
    var diff = Math.abs(today - dateObj);
    diff = Math.ceil(diff / (1000 * 3600 * 24));
    return diff;
  }
  var startDate = new Date(start);
  var endDate = new Date(end);
  var timeDiff = endDate.getTime() - startDate.getTime();
  var daysDiff = timeDiff / (1000 * 3600 * 24);
  return daysDiff;
}
function getLocalStorage(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
function getPercentage(info, path, diffDate) {
  if (diffDate === false) return false;
  var averageStages = JSON.parse(getLocalStorage('avg_dur'));
  var averageDuration;
  try {
    averageDuration = averageStages[path.initiativeType][info.state] / 1000 / 3600 / 24;
  } catch {
    return false;
  }
  if (isNaN(averageDuration)) {
    return false;
  }
  var percentage = Math.round((diffDate * 100) / averageDuration) - 100;
  var color;
  if (percentage <= 0) {
    color = 'green-d';
  }
  if (percentage > 0) {
    color = 'red';
  }
  return { percentage, color };
}

export default function useDays(props) {
  const { info, path, title } = props;

  const [startDate, setStartDate] = useState(0);
  const [diffDate, setDiffDate] = useState(0);

  useEffect(() => {
    setStartDate(reverseDate(info.startDate));
    setDiffDate(getDiffDate(info.startDate, info.endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { percentage, color } = getPercentage(info, path, diffDate);
  const noDate = !info.endDate && !info.startDate;

  const showPercentage = () => {
    const isValidDate = !noDate && diffDate !== undefined && !diffDate.isNan;
    const isValidResolved = !info.endDate && info.isResolved;
    return !isValidResolved && isValidDate;
  };

  return (
    <div className='card-single__inner'>
      {startDate !== 0 && <p className='e-date'>Desde: {startDate}</p>}
      <h2 className='c-title c-title--xl'>{title}</h2>
      {showPercentage() && (
        <div className='card__time'>
          <span>{diffDate} días en trámite</span>
          {percentage !== undefined && !percentage.isNan && percentage !== 0 && (
            <span className={color}>{percentage >= 0 ? '+' + percentage : percentage}% de lo habitual</span>
          )}
        </div>
      )}
    </div>
  );
}
