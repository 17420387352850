import { useState, useEffect } from 'react';
import getAPI from 'services/getAPI';
import { filterByList } from 'utils/dataArray';

function filterAuthorsResponse(props) {
  const { filtersList, authorsOptions, setFiltersList, FILTER, index } = props;
  const selectedAuthors = FILTER[filterByList.author];
  const array = authorsOptions.map(a => a.name);
  const selectedFiltered = selectedAuthors.filter(eachOne => !array.includes(eachOne.name));

  const authorsList = selectedFiltered.concat(authorsOptions);

  let fullList = [...filtersList];
  fullList[index].list = authorsList;
  setFiltersList(fullList);
}

export default function useAuthorSearch(props) {
  const [isSearching, setIsSearching] = useState(false);
  const { filtersList, setFiltersList, FILTER } = props;
  const indexAuthor = filtersList.findIndex(f => f.groupTitle === filterByList.author);
  const [keyword, setKeyword] = useState('');
  const handleAuthorInputChange = e => {
    setKeyword(e.target.value);
  };

  const fetchData = async () => {
    const query = {
      ...{ name: keyword.length === 0 ? 'psoe' : keyword },
      size: '6',
    };
    await getAPI({
      url: 'initiativeAuthors',
      query: query,
      typeQuery: 'obj',
    })
      .then(res => {
        const newOptions = res.response.map(obj => {
          var newObj = {};
          const path = obj._source;
          newObj['name'] = path?.name || null;
          newObj['src'] = path?.image?.url || null;
          newObj['isPerson'] = path.deputyId !== null;
          return newObj;
        });

        filterAuthorsResponse({
          index: indexAuthor,
          authorsOptions: newOptions,
          FILTER: FILTER,
          filtersList: filtersList,
          setFiltersList: setFiltersList,
        });
      })
      .then(() => {
        setIsSearching(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    filterAuthorsResponse({
      index: indexAuthor,
      authorsOptions: filtersList[indexAuthor].list,
      FILTER: FILTER,
      filtersList: filtersList,
      setFiltersList: setFiltersList,
    });
    if (keyword.length === 0 || keyword.length >= 2) {
      setIsSearching(true);
      var timer = setTimeout(async () => {
        await fetchData();
      }, 100);
    } else if (keyword.length < 2) {
      let fullList = [...filtersList];
      fullList[indexAuthor].list = FILTER[filterByList.author];
      setFiltersList(fullList);
      setIsSearching(false);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FILTER[filterByList.author], keyword]);

  return { isSearching, keyword, handleAuthorInputChange };
}
