import React from 'react';

export default function ModalHeader(props) {
  const { children, handleCloseModal, activeModal, activeModalValue, title, titleGenre } = props;

  return (
    <div className={`modal__inner${activeModal === activeModalValue ? ' active' : ''}`}>
      <div className='modal__mobile-nav'>
        <span id='modalNav' onClick={handleCloseModal}>
          Aprobación de {title}
        </span>
      </div>

      <span className='modalClose' onClick={handleCloseModal}></span>

      <div className='modal__header'>
        <h2 className='c-title c-title--xxxl'>
          Así se aprueba <br /> {titleGenre === 'f' ? 'una' : 'un'} {title}{' '}
        </h2>
      </div>

      {children}
    </div>
  );
}
