import React from 'react';
import AmendmentsItem from './AmendmentsItem';
function compare(a, b) {
  if (a.amendmentCount > b.amendmentCount) {
    return -1;
  }
  if (a.amendmentCount < b.amendmentCount) {
    return 1;
  }
  return 0;
}
export default function Amendments(props) {
  const { info, total } = props;
  return (
    <>
      <div className='card-single__inner'>
        <div className='b-squares'>
          <div className='b-squares__header'>
            <h2 className='c-title c-title--l'>Enmiendas presentadas</h2>
            <h3 className='b-squares__title'>{total}</h3>
            <p className='b-squares__subtitle'>En total</p>
          </div>

          {info.sort(compare).map((eachOne, i) => {
            const num = (230 / info.length) * i;
            return (
              <AmendmentsItem
                key={eachOne.parliamentaryGroups[0].name}
                count={eachOne.amendmentCount}
                name={eachOne.parliamentaryGroups[0].name}
                color={eachOne.parliamentaryGroups[0].color ? eachOne.parliamentaryGroups[0].color : `rgb(${num},${num},${num})`}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
