import useClickOutside from 'hooks/useClickOutside';
import { useState } from 'react';

export default function useFocus(props) {
  const { ref } = props;
  const [isFocus, setFocus] = useState(false);
  const handleMobileBack = () => {
    setFocus(false);
  };
  const handleMobileFocus = () => {
    if (window.matchMedia('(max-width: 768px)')) {
      setFocus(true);
    }
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => setFocus(false),
  });

  return {
    isFocus,
    handleMobileBack,
    handleMobileFocus,
  };
}
