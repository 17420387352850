import React from 'react';
import AuthorImg from 'elements/AuthorImg';
import { listIncludes } from 'utils/functions';
import autorDefault from 'img/fake/_autor.svg';
import { filterByList, processingResultAprobada, processingResultConvalidada, processingResultConvalidadaTramitada } from 'utils/dataArray';
import IconCat from 'elements/IconCat';
import ReactTooltip from 'react-tooltip';

export default function FilterListItem(props) {
  const { groupTitle, handleClickFilter, FILTER, MBQueueFilter = [], isInnerOpen = false, id, name, src, data, searchName } = props;

  const tooltipId =
    groupTitle === filterByList.processingResult && (name === processingResultConvalidada || name === processingResultConvalidadaTramitada)
      ? name
      : undefined;
  const alterConvalidada = tooltipId && listIncludes(FILTER[groupTitle], processingResultAprobada);

  const img = () => {
    if (groupTitle === filterByList.author) {
      if (typeof src === 'string') {
        return <AuthorImg src={src} name={name} />;
      } else {
        return <AuthorImg src={autorDefault} name={name} />;
      }
    }
  };

  const isChecked = () => {
    var isInFilter;
    var isInFilterMB;
    if (groupTitle === filterByList.orderBy) {
      // Filter with need of translation
      isInFilter = listIncludes(FILTER[groupTitle], searchName);
      isInFilterMB = listIncludes(MBQueueFilter, searchName);
    } else if (groupTitle === filterByList.legislature) {
      // Filter with need of translation
      isInFilter = listIncludes(FILTER[groupTitle], id);
      isInFilterMB = listIncludes(MBQueueFilter, id);
    } else if (groupTitle === filterByList.euDriven) {
      isInFilter = listIncludes(FILTER[groupTitle], data);
      isInFilterMB = listIncludes(MBQueueFilter, data);
    } else {
      // Filter with no need of translation
      isInFilter = listIncludes(FILTER[groupTitle], name);
      isInFilterMB = listIncludes(MBQueueFilter, name);
    }

    // Desktop
    if (window.innerWidth >= 767 && isInFilter) {
      return true;
    }
    // Tablet and mobile
    if (window.innerWidth < 767) {
      if (!isInnerOpen && isInFilter) {
        return true;
      }
      if (isInnerOpen && isInFilterMB) {
        return true;
      }
    }
    return false;
  };
  const mobileIconImage = groupTitle => {
    if (window.innerWidth < 767 && groupTitle === 'Categoría') {
      return <IconCat name={name} />;
    } else {
      return false;
    }
  };

  const getNameByFilterGroup = searchName => {
    if (searchName) {
      return searchName;
    }
    if (groupTitle === filterByList.legislature) {
      return id;
    }
    if (groupTitle === filterByList.euDriven) {
      return data;
    }
    return name;
  };

  return (
    <>
      <ReactTooltip id={tooltipId} />
      <dd data-tip={alterConvalidada ? 'Primero desmarca "Aprobada" para desmarcar este campo' : undefined}>
        {img()}
        <div className='e-filter__group-inner'>
          {mobileIconImage(groupTitle)}
          {name && <span className='name'>{name}</span>}
        </div>
        <label
          className={`e-filter__group-checkbox input--checkbox${isChecked() ? ' checked' : ''}`}
          style={{ cursor: alterConvalidada ? 'auto' : 'pointer' }}
        >
          <input
            disabled={alterConvalidada ? 'disabled' : ''}
            type='checkbox'
            value={name}
            data-value={getNameByFilterGroup(searchName)}
            onChange={e => handleClickFilter(e, groupTitle)}
            checked={isChecked()}
          />
        </label>
      </dd>
    </>
  );
}
