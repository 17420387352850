import { useState, useEffect } from 'react';
import { modalButtons } from 'utils/dataArray';

export default function useFilterModal(props) {
  const { singleIn, isFetched } = props;
  const [modalIndex, setModalIndex] = useState(-1);
  useEffect(() => {
    if (singleIn.initiativeType) {
      const modalIndex = modalButtons.findIndex(modal => {
        const regex = /(?:\s(de|del)\s)/g;
        const thisInitiative = singleIn.initiativeType.toLowerCase().replace(regex, ' ');

        const modalTitle = modal.title.toLowerCase().replace(regex, ' ');

        return thisInitiative.includes(modalTitle);
      });
      setModalIndex(modalIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  return modalButtons[modalIndex];
}
