import React from 'react';
import buscador from 'img/content/PANTALLAZOS_1.jpg';
import stories from 'img/content/PANTALLAZOS_2.jpg';
import iniciativa from 'img/content/PANTALLAZOS_3.jpg';
import iconos from 'img/content/PANEL_4_ICONOS.png';
import watch from 'img/content/PANEL_POLITICAL_WATCH.png';
import logos from 'img/content/PANEL_2_LOGOS.png';
import email from 'img/content/EMAIL_CONTACTO.png';
import parlamentia_sept_21 from 'img/content/parlamentia_sept_21.png';
import robot from 'img/content/robot_datos-01.jpg';
import status from 'img/fake/_status.png';
import useBodyClass from 'hooks/useBodyClass';
import { SEO } from 'elements/SEO';
import AboutPanel from 'components/About/AboutPanel';

function About(props) {
  const { handleIsBurguerOpen } = props;
  useBodyClass('parlamentia', handleIsBurguerOpen);
  return (
    <>
      <SEO
        title='Qué es Parlamentia - Newtral'
        description='Parlamentia es la primera plataforma que te explica cómo se hacen las leyes en España, desde que llegan al Congreso hasta que se publican en el BOE.'
        url={window.location.href}
        creator=''
      />
      <div id='content' className='page-content'>
        <section className='b-header'>
          <div className='container'>
            <h1 className='c-title c-title--xxxxl'>¿Qué es Parlamentia?</h1>
            <h2 className='b-header__subtitle'>
              Parlamentia es la primera plataforma que te explica cómo se hacen las leyes en España, desde que llegan al Congreso hasta que
              se publican en el BOE
            </h2>
          </div>
        </section>

        <AboutPanel title='¿Qué puedes encontrar en Parlamentia?' textPosition='left' src={iconos} alt='Iconos'>
          <ul className='b-starred__status'>
            <li>
              <span>
                <strong>Acceso directo y claro</strong> a las leyes que se tramitan en el Congreso de los Diputados durante la legislatura.
              </span>
            </li>
            <li>
              <span>
                Resúmenes explicativos sobre <strong>las propuestas legislativas</strong> y el impacto que tendrán en el día a día de los
                ciudadanos.
              </span>
            </li>
            <li>
              <span>
                Explicación sencilla sobre <strong>cómo se desarrolla el proceso</strong> y qué implica cada una de las fases.
              </span>
            </li>
            <li>
              <span>
                Datos y contexto sobre lo que ocurre en las diferentes <strong>etapas de tramitación</strong>.
              </span>
            </li>
            <li>
              <span>
                Recopilación de las leyes aprobadas <strong>desde la Transición</strong> por el Congreso.
              </span>
            </li>
          </ul>
        </AboutPanel>

        <AboutPanel title='Busca la ley' textPosition='right' src={buscador} alt='Captura de pantalla del buscador'>
          <p>
            Permite <strong>localizar todas las propuestas para tramitar una ley</strong> que se presentan durante la legislatura en el
            Congreso. Se puede buscar tanto por palabra clave (ej: becas, Código Penal..) como en función de varios aspectos: temática,
            autor o fase de tramitación en la que se encuentra.{' '}
          </p>
        </AboutPanel>

        <AboutPanel title='Consulta las claves de la norma' textPosition='left' src={stories} alt='Captura de pantalla de una story'>
          <p>
            Cuando se selecciona una norma, aparece un resumen del proceso en formato Stories. El objetivo es que, de un vistazo, cualquier
            usuario pueda entender lo que dice la iniciativa y cómo le afecta.
          </p>
        </AboutPanel>

        <AboutPanel title='Acceso al detalle de la ley' textPosition='right' src={iniciativa} alt='Captura de pantalla de una iniciativa'>
          <p>
            Se despliega <strong>el detalle de las etapas de tramitación</strong> que ha seguido la norma con datos recopilados de forma
            automática: votaciones relevantes, enmiendas presentadas por los partidos o estadísticas sobre la duración del proceso, entre
            otros.
          </p>
          <p>
            Además, las normas más destacadas contarán con una <strong>explicación extra</strong> sobre cómo repercutirá la ley en el día a
            día de los ciudadanos.
          </p>
        </AboutPanel>

        <AboutPanel title='¿Quiénes hacemos Parlamentia?' textPosition='left' src={watch} alt='Logos'>
          <p>
            Parlamentia es el portal de leyes en España ideado, diseñado e implementado por <b>periodistas e ingenieros de</b>{' '}
            <a href='https://newtral.es' target='_blank' rel='noreferrer'>
              <strong>Newtral.es</strong>
            </a>
            , con la colaboración de la consultora{' '}
            <a href='https://harmon.es' target='_blank' rel='noreferrer'>
              <strong>Harmon</strong>
            </a>{' '}
            y de Political Watch {'('}
            <a href='https://quehacenlosdiputados.es' target='_blank' rel='noreferrer'>
              <strong>Qué hacen los diputados</strong>
              {')'}
            </a>
            , que aporta la base tecnológica para recopilar los datos de las leyes tramitadas por el Congreso. También contamos con el apoyo
            del{' '}
            <a href='https://www.newtral.es/parlamentia-normas-europeas/20240603/' target='_blank' rel='noreferrer'>
              <strong>Parlamento Europeo</strong>.
            </a>{' '}
          </p>
        </AboutPanel>

        <AboutPanel title='¿Cómo es nuestra metodología?' textPosition='right' src={robot} alt='Robot'>
          <p>
            En Parlamentia revisamos <strong>cada día</strong> con un margen de 48h la nueva información publicada por el Congreso, que se{' '}
            <strong>categoriza automáticamente</strong> y se estructura para su análisis. Fases de tramitación, votaciones, enmiendas,
            ampliación de plazos y documentos son extraídos por nuestros crawlers (robots que automáticamente extraen información de la web)
            e incorporados a la base de datos de Parlamentia.
          </p>
          <p>
            A partir de aquí, el equipo <strong>revisa manualmente esta información</strong> y la enriquece con resúmenes y explicaciones de
            las leyes más relevantes para los ciudadanos.
          </p>
          <p>
            A medida que una ley avanza en su proceso de tramitación, <strong>algoritmos y periodistas</strong> continuamos realizando este
            trabajo combinado para construir una historia consistente sobre qué ha ocurrido con cada ley desde su propuesta inicial.
          </p>
        </AboutPanel>

        <AboutPanel title='¿Cómo hemos simplificado la información?' textPosition='left' src={status}>
          <p>
            Tras estudiar las diferentes tramitaciones que siguen las potenciales leyes, hemos simplificado los estados en los que puede
            aparecer una iniciativa.
          </p>
          <ul className='b-starred__status'>
            <li className='approved'>
              <span>
                <strong>Aprobada</strong>. La propuesta ha salido adelante tras contar con el apoyo mayoritario del Congreso y del Senado.
                En el caso de los decretos leyes, se incluyen los que la Cámara baja ha convalidado.
              </span>
            </li>
            <li className='processing'>
              <span>
                <strong>En tramitación</strong>. La iniciativa está en alguna de las fases de tramitación del Congreso o del Senado.
              </span>
            </li>
            <li className='rejected'>
              <span>
                <strong>Rechazada</strong>. La tramitación ha terminado porque una mayoría parlamentaria se ha opuesto a la iniciativa. En
                el caso de los decretos leyes, se incluyen los que la Cámara baja ha derogado.
              </span>
            </li>
            <li className='not-approved'>
              <span>
                <strong>No aprobada</strong>. La propuesta no se está tramitando por diversos motivos, porque la norma ha decaído a
                consecuencia de una convocatoria electoral, ha caducado, ha sido inadmitida o se ha subsumido en otra iniciativa, entre
                otros.
              </span>
            </li>
          </ul>
        </AboutPanel>

        <AboutPanel title='¿Quién nos inspira?' textPosition='right' src={logos} alt='Logos'>
          <ul className='b-starred__status'>
            <li className='without-bullets'>
              <span>
                <strong>
                  <a href='https://www.govtrack.us/'>Govtrack</a>
                </strong>
                . La cantidad de información que ofrecen sobre la actividad del Congreso de Estados Unidos y el nivel de detalle con que la
                exponen lo convierten en la principal referencia de este proyecto.
              </span>
            </li>
            <li className='without-bullets'>
              <span>
                <strong>
                  <a href='https://projects.propublica.org/represent/members/O000172-alexandria-ocasio-cortez/compare-votes/B001296-brendan-boyle/117'>
                    Represent de Propublica
                  </a>
                </strong>
                . Nos ha resultado muy interesante la manera en que representan las votaciones del Congreso y del Senado estadounidense.
                ¿Para qué escribir dos párrafos si se puede explicar mejor con un gráfico?
              </span>
            </li>
          </ul>
        </AboutPanel>

        <AboutPanel title='Ayúdanos a corregir errores' textPosition='left' src={email} alt='Contacto'>
          <p>
            La tecnología de Parlamentia está diseñada para extraer los datos del Congreso de la forma en que estos están organizados ahora.
            Dependemos al 100% de la información que facilita la institución, o dicho de otra manera: cuando el Congreso cambie algún
            detalle en la organización de los datos, tendremos que ajustar nuestros parámetros para poder mostrar con precisión las
            estadísticas y detalles de las leyes.
          </p>
          <p>
            Aunque el equipo ha ideado un sistema para detectar estas modificaciones, si ves algún fallo o tienes cualquier sugerencia, no
            dudes en escribirnos a{' '}
            <a href='mailto:parlamentia@newtral.es' target='_blank' rel='noreferrer'>
              parlamentia@newtral.es
            </a>
            . ¡Por favor y gracias!
          </p>
        </AboutPanel>

        <AboutPanel title='Nuestra historia hasta ahora' src={parlamentia_sept_21} textPosition='right' black>
          <p>
            <strong>Abril de 2023</strong>. Lanzamos Parlamentia como una plataforma en la que poder hacer el seguimiento, paso a paso, de
            la leyes que se aprueban en las Cortes.
          </p>
        </AboutPanel>
      </div>
    </>
  );
}

export default About;
