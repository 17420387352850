import useClickOutside from 'hooks/useClickOutside';
import { useState } from 'react';

export default function useOpen(props) {
  const { filtersList, ref } = props;
  const getFilterInitial = arrayValues => {
    const desktopIsOpen = [];
    for (let i = 0; i < arrayValues.length; i++) {
      const element = arrayValues[i];
      desktopIsOpen.push({ groupTitle: element.groupTitle, isOpen: false });
    }
    return desktopIsOpen;
  };
  const [arrayOpen, setArrayOpen] = useState(getFilterInitial(filtersList));
  useClickOutside({
    ref: ref,
    clickOutside: () => setArrayOpen(getFilterInitial(filtersList)),
  });

  const handleOpenFilter = (e, groupTitle) => {
    let newArray = arrayOpen.map(eachOne => {
      if (eachOne.groupTitle === groupTitle) {
        if (eachOne.isOpen === true) {
          return { groupTitle: eachOne.groupTitle, isOpen: false };
        } else {
          return { groupTitle: eachOne.groupTitle, isOpen: true };
        }
      }
      return { groupTitle: eachOne.groupTitle, isOpen: false };
    });
    setArrayOpen(newArray);
  };

  return {
    arrayOpen,
    handleOpenFilter,
  };
}
