import VoteGraphic from 'components/Vote/VoteGraphic';
import VoteList from 'components/Vote/VoteList';
import React from 'react';

function getVotes(info) {
  var listVotes = { yes: [], no: [], abstention: [], noVote: [] };
  for (const key in info.voteByParliamentaryGroup) {
    if (Object.hasOwnProperty.call(info.voteByParliamentaryGroup, key)) {
      const parlGroup = info.voteByParliamentaryGroup[key];
      const id = parlGroup.parliamentaryGroupId;
      for (const key in parlGroup.voteCount) {
        if (Object.hasOwnProperty.call(parlGroup.voteCount, key)) {
          const number = parlGroup.voteCount[key];
          if (number > 0) listVotes[key].push({ id, number });
        }
      }
    }
  }
  return listVotes;
}
export default function StoriesVoting(props) {
  let { info } = props;
  let votes;
  let parliamentaryGroups;
  if (info?.voting) {
    const voting = JSON.parse(info?.voting);
    votes = getVotes(voting);

    parliamentaryGroups = JSON.parse(info?.parliamentaryGroups);
    info = {
      ...info,
      parliamentaryGroups: parliamentaryGroups?.map(e => e?._id),
      voteCount: voting.voteCount,
      voteByParliamentaryGroup: voting?.voteByParliamentaryGroup,
    };
  } else {
    const voteCount = info?.voteCount;
    votes = getVotes(info);

    const voteByParliamentaryGroup = info.voteByParliamentaryGroup;

    parliamentaryGroups = voteByParliamentaryGroup?.flatMap(each => each.parliamentaryGroup);
    info = {
      ...info,
      parliamentaryGroups: parliamentaryGroups,
      voteCount: voteCount,
      voteByParliamentaryGroup: voteByParliamentaryGroup,
    };
  }

  const totalVotes = Object.values(info.voteCount).reduce((t, n) => t + n, 0);

  const title = info.subGroupTitle ? `${info.title} (${info.subGroupTitle})` : info.title;
  return (
    <div className='story__voting__container'>
      <div className='story__voting'>
        <div className='b-vote b-vote--alt'>
          <h3 className='b-vote__title'>{title}</h3>
          <h2 className='c-title c-title--l'>Votación</h2>
          <div className='b-vote__graphic-wrapper'>
            <div className='b-vote__graphic-header'>
              <strong className='yes'>Sí: {info.voteCount.yes}</strong>
              <strong className='no'>No: {info.voteCount.no}</strong>
            </div>
            <VoteGraphic info={info} totalVotes={totalVotes} />

            {Object.keys(votes).map((key, i) => (
              <VoteList key={key + '_' + i} name={key} votes={votes[key]} info={info} parliamentaryGroups={parliamentaryGroups} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
