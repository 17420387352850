import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import IconCat from 'elements/IconCat';

import autorDefault from 'img/fake/_autor.svg';

import LegislatureContext from 'context/LegislatureContext';

function getType(info) {
  let type = 'category';
  const hasDeputyId = Object.prototype.hasOwnProperty.call(info, 'deputyId');
  const hasSummary = Object.prototype.hasOwnProperty.call(info, 'summary');
  if (hasDeputyId) {
    type = 'author';
  }
  if (hasSummary) {
    type = 'law';
  }
  return type;
}
function getTitle(info, type) {
  const title = type === 'law' ? info.title : info.name;
  return title;
}
function getImage(info, type, title) {
  if (type === 'author') {
    if (info.image !== null && info.image !== 'null' && info?.image?.url) {
      return (
        <figure className='image'>
          <img src={info.image.url} alt={title} />
        </figure>
      );
    } else {
      return (
        <figure className='image'>
          <img src={autorDefault} alt={title} />
        </figure>
      );
    }
  } else if (type === 'category') {
    return (
      <figure className='image'>
        <IconCat name={info.name} />
      </figure>
    );
  }
}
function getLegislature(legislatureId, legislaturesList) {
  return legislaturesList.find(x => {
    return x.id === legislatureId;
  });
}
function getLink(info, type, title, legislaturesList) {
  if (type === 'law') {
    const legislature = getLegislature(info.legislatureId, legislaturesList);
    const legSlugNumber = legislature?.slug.split('-')[0];
    const legSlugName = legislature?.slug.split('-')[1];
    return info?.storyId && info?.storyStatus === 'published'
      ? `${legSlugName + '-' + legSlugNumber}/resumen/${info.slug}`
      : `${legSlugName + '-' + legSlugNumber}/ley/${info.slug}`;
  } else {
    return `/leyes/${type}=${title}&sort=score&size=9`;
  }
}

function SearchItem(props) {
  const { legislaturesList } = useContext(LegislatureContext);
  if (!props) {
    return null;
  }
  const { info } = props;
  const type = getType(info);
  const title = getTitle(info, type);
  const image = getImage(info, type, title);
  const link = getLink(info, type, title, legislaturesList);

  return (
    <>
      <li>
        <Link to={{ pathname: link, fromParlamentia: true }}>
          <span className='info'>
            <span className='name'>
              <span className='name'>{info.titleAlias ? info.titleAlias : title}</span>
            </span>
            <span className='role'></span>
          </span>
          {image}
        </Link>
      </li>
    </>
  );
}

export default SearchItem;
