import { useEffect, useState } from 'react';
import getAPI from 'services/getAPI';

function setLocalStorage(key, value, hours) {
  const now = new Date();
  const ttl = hours * 60 * 60 * 1000;
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
function getLocalStorage(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return false;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return false;
  }
  return item.value;
}

export default function useAverageStage() {
  const [averageStage, setAverageStage] = useState({});
  const storedData = getLocalStorage('avg_dur');
  useEffect(() => {
    if (storedData) {
      setAverageStage(JSON.parse(storedData) || null);
    } else {
      const numbers = {};
      getAPI({ url: 'durationAverage', typeQuery: 'noQuery' }).then(res => {
        for (let i = 0; i < res.response.length; i++) {
          const element = res.response[i];
          const type = element._source.initiativeType;
          const stage = element._source.stage;
          const duration = element._source.averageDuration;
          const newObj = { [stage]: duration };
          if (!numbers[type]) numbers[type] = {};
          Object.assign(numbers[type], newObj);
        }
        setAverageStage(numbers);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!storedData && Object.keys(averageStage).length !== 0) {
    setLocalStorage('avg_dur', JSON.stringify(averageStage), 1);
  }
}
