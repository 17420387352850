import React, { useState } from 'react';
import useLegislatures from 'hooks/useLegislatures';

const Context = React.createContext({ legislaturesList: [{}] });

export const LegislatureContext = ({ children }) => {
  const [values, setValues] = useState({ legislaturesList: [{}] });

  const { ready } = useLegislatures({ setValues: setValues });

  if (!ready) {
    return <></>;
  }
  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export default Context;
