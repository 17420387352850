import React from 'react';
import Pagination from 'elements/Results/Pagination';
import SingleCardSmall from 'components/SingleCardSmall';

export default function MainResults(props) {
  const { results, legacy, URLKeyword, page, totalResults, setPage, URLPage } = props;
  return (
    <>
      <div className='container container--grid'>
        {results.map((eachOne, i) => (
          <SingleCardSmall
            key={'cardlaw_' + results[i]._source.id}
            path={results[i]._source}
            legacy={legacy ? true : false}
            keyword={URLKeyword}
          />
        ))}
      </div>
      <Pagination page={page} URLKeyword={URLKeyword} totalResults={totalResults} setPage={setPage} URLPage={URLPage} />
    </>
  );
}
