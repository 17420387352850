import React from 'react';

export default function MainModalHeader(props) {
  const { children, handleCloseMainModal, activeMainModal, activeMainModalValue, title } = props;

  return (
    <div className={`modal__inner${activeMainModal === activeMainModalValue ? ' active' : ''}`}>
      <span className='modalClose close_main_modal' onClick={handleCloseMainModal}></span>

      <div className='modal__header title_main_modal'>
        <h2 className='c-title c-title--xxxl' dangerouslySetInnerHTML={{ __html: title }} />
      </div>

      {children}
    </div>
  );
}
