import { useContext } from 'react';
import { filterByList, sortByList, euDrivenList } from 'utils/dataArray';
import LegislatureContext from 'context/LegislatureContext';

export default function SelectedFilterName(props) {
  const { filterLength, name, i, groupTitle, FILTER } = props;

  const { legislaturesList } = useContext(LegislatureContext);

  if (groupTitle === filterByList.orderBy) {
    const name = sortByList.find(x => {
      return x.searchName === FILTER[groupTitle][0].name;
    }).name;
    return name;
  }

  if (groupTitle === filterByList.legislature) {
    const name = legislaturesList.find(x => {
      return x.id === FILTER[groupTitle][0].name;
    }).name;
    return name;
  }

  if (groupTitle === filterByList.euDriven) {
    const name = euDrivenList.find(x => {
      return x.data === FILTER[groupTitle][0].name;
    }).name;
    return name;
  }
  if (i === filterLength - 1) {
    return name;
  }
  return name + ', ';
}
