import React, { useState, useRef } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { modalButtons } from 'utils/dataArray';
import ModalButton from './ModalButton';

export default function DropdownLaws(props) {
  const { handleOpenModal } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const wrapperRef = useRef(null);
  useClickOutside({
    ref: wrapperRef,
    clickOutside: () => {
      setIsDropdownOpen(false);
    },
  });
  return (
    <div className='e-dropdown' ref={wrapperRef}>
      <span className={`e-dropdown__open modalOpen${isDropdownOpen ? ' open' : ''}`} data-open onClick={handleDropdownClick}>
        Cómo se aprueban las leyes
      </span>
      <div className={`e-dropdown__content${isDropdownOpen ? ' active' : ''}`} data-content>
        <ul>
          {modalButtons.map(eachOne => {
            return (
              <li key={'modalButtons_' + eachOne.data + '_header'}>
                <ModalButton title={eachOne.title} data={eachOne.data} handleOpenModal={handleOpenModal} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
