import React, { useEffect, useRef } from 'react';
import ModalBanner from 'elements/Modals/ModalBanner';
import ModalHeader from 'elements/Modals/ModalHeader';
import ModalContent from 'elements/Modals/ModalContent';
import { fadeIn, fadeOut } from 'utils/functions';
import { modalButtons } from 'utils/dataArray';

export default function ModalLaws(props) {
  const { activeModal, handleCloseModal } = props;
  const modal = useRef(null);
  const modalNames = modalButtons.map(eachOne => eachOne.data);

  useEffect(() => {
    modalNames.includes(activeModal) ? fadeIn(modal.current) : fadeOut(modal.current);
  }, [activeModal, modalNames]);

  return (
    <div className='modal modal--laws' id='modalLaws' ref={modal}>
      <ModalHeader
        handleCloseModal={handleCloseModal}
        activeModal={activeModal}
        activeModalValue='proyectoDeLey'
        title='Proyecto de Ley'
        titleGenre='m'
      >
        <ModalContent
          title='1. Registro y calificación de la Mesa del Congreso'
          icons={[
            { type: 'quien', title: 'Gobierno' },
            {
              type: 'que',
              title: 'Publicación en el Boletín Oficial de las Cortes Generales',
            },
          ]}
        >
          <p>
            El Gobierno remite el proyecto de ley al Congreso. La Mesa lo recibe, ordena su publicación en el Boletín Oficial de las Cortes
            Generales, abre el plazo de enmiendas y lo remite a la comisión correspondiente.
          </p>
        </ModalContent>
        <ModalContent
          title='2. Enmiendas'
          icons={[
            { type: 'quien', title: 'Grupos parlamentarios' },
            {
              type: 'que',
              title: 'Propuestas de cambios o devolución del texto',
            },
            {
              type: 'cuando',
              title: '15 días hábiles (prorrogables por la Mesa)',
            },
          ]}
        >
          <p>Las formaciones pueden presentar enmiendas de dos tipos:</p>
          <ul>
            <li>
              <strong>Enmiendas a la totalidad</strong>: proponen la devolución del proyecto de ley al Gobierno o su sustitución por un
              nuevo texto completo.
            </li>
            <li>
              <strong>Al articulado</strong>: plantean eliminar, cambiar o añadir artículos a la iniciativa.
            </li>
          </ul>
        </ModalContent>
        <ModalContent
          title='3. Pleno: debate de totalidad'
          bannerTop={[
            {
              color: 'red',
              title: 'Si se han presentado enmiendas a la totalidad',
            },
          ]}
          icons={[
            {
              type: 'que',
              title: 'Votación sobre las enmiendas a la totalidad',
            },
            {
              type: 'votacion',
              title: 'Mayoría simple (más síes que noes)',
            },
          ]}
          bannerBottom={[
            {
              color: 'green',
              arrow: 'down',
              title: 'Si no hay enmiendas a la totalidad o son rechazadas por la mayoría',
            },
          ]}
        >
          <p>
            Si se han presentado enmiendas a la totalidad, se celebra un pleno donde los diputados se pronuncian sobre la propuesta de
            devolución o cambio total del texto. Para ser aprobadas necesitan una mayoría simple de apoyos. Si no los consigue, el proyecto
            de ley continúa su tramitación.
          </p>
        </ModalContent>
        <ModalContent
          title='4. Comisión: ponencia y dictamen'
          icons={[
            {
              type: 'quien',
              title: 'Diputados en representación de los Grupos',
            },
            {
              type: 'que',
              title: 'Elaboración de un dictamen',
            },
          ]}
          bannerBottom={[
            {
              color: 'green',
              arrow: 'cornerDown',
              title: 'Si la comisión ejerce la competencia legislativa plena, el proyecto de ley pasa directamente al Senado',
            },
          ]}
        >
          <p>
            La comisión elige entre sus miembros a una ponencia —un grupo de diputados que representa a las formaciones parlamentarias— para
            que elabore un <strong>informe</strong> con el texto remitido por el Gobierno y las enmiendas acordadas.
          </p>
          <p>
            A continuación, la comisión debate y vota este texto, así como las propuestas que no hayan sido incorporadas. Después, emite un
            dictamen que debe ratificar el pleno de la Cámara.
          </p>
        </ModalContent>
        <ModalContent
          title='5. Pleno: deliberación'
          icons={[
            {
              type: 'que',
              title: 'Votación del dictamen y enmiendas ‘vivas’',
            },
            {
              type: 'votacion',
              title: 'Mayoría simple (más síes que noes)',
            },
          ]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                'Si es una ley orgánica, se produce una votación final en la que hace falta una mayoría absoluta de apoyos (176 síes) para que se apruebe',
            },
          ]}
        >
          <p>
            El pleno del Congreso se pronuncia sobre el dictamen emitido por la comisión. También, sobre las enmiendas que en la comisión no
            han contado con los apoyos necesarios, pero que los grupos han decidido mantener. El texto necesita más síes que noes para ser
            aprobado.
          </p>
        </ModalContent>
        <ModalContent
          title='6. Senado'
          icons={[
            { type: 'que', title: 'Continuación de la tramitación del texto' },
            { type: 'cuando', title: '2 meses' },
          ]}
        >
          <p>La Cámara alta recibe el proyecto de ley aprobado por el Congreso y lo tramita, pudiendo realizar tres acciones:</p>
          <ul>
            <li>
              <strong>Ratificar el texto</strong> en los mismos términos en los que lo aprobó el Congreso
            </li>
            <ModalBanner type='green'>El proyecto de ley queda aprobado de forma definitiva</ModalBanner>
            <li>
              <strong>Introducir enmiendas</strong> mediante un proceso similar al del Congreso.
            </li>
            <li>
              <strong>Vetar el texto</strong> por mayoría absoluta (la mitad más uno de los senadores).
            </li>
          </ul>
        </ModalContent>
        <ModalContent
          title='7. Pleno: ratificación'
          bannerTop={[
            {
              color: 'red',
              title: 'Solo si el Senado introduce enmiendas o veta el proyecto de ley',
            },
          ]}
          icons={[{ type: 'que', title: 'Votación de los cambios de la Cámara alta' }]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                'Si es una ley orgánica, se produce una votación final en la que hace falta una mayoría absoluta de apoyos (176 síes) para que se apruebe.',
            },
          ]}
        >
          <p>
            Si el Senado introduce enmiendas o veta el proyecto de ley, el texto debe volver al Congreso para que ratifique estos cambios o
            levante el veto.
          </p>
          <ul>
            <li>
              <strong>Enmiendas</strong>: puede aprobarlas o rechazarlas por mayoría simple.
            </li>
            <li>
              <strong>Veto</strong>: tiene la posibilidad de levantar este ‘no’ si aprueba por mayoría absoluta la versión aprobada por el
              Congreso. Si no la obtuviera, se volvería a repetir la votación a los dos meses desde que se interpuso el veto. Entonces,
              bastaría con el apoyo de una mayoría simple de los diputados para desbloquearlo.
            </li>
          </ul>
        </ModalContent>
        <ModalContent title='8. Publicación en el BOE'>
          <div className='c-content'>
            Una vez aprobada, la ley es sancionada por el Rey, promulgada y publicada en el Boletín Oficial del Estado.
          </div>
        </ModalContent>
      </ModalHeader>

      <ModalHeader
        handleCloseModal={handleCloseModal}
        activeModal={activeModal}
        activeModalValue='proposicionDeLey'
        title='proposición de ley'
        titleGenre='f'
      >
        <ModalContent
          title='1. Registro y calificación de la Mesa del Congreso'
          icons={[
            {
              type: 'quien',
              title: 'Grupos parlamentarios, 15 diputados, Senado, CCAA o los ciudadanos',
            },
            {
              type: 'que',
              title: 'Publicación en el Boletín Oficial de las Cortes Generales',
            },
          ]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                'En el caso de las Iniciativas Legislativas Populares no pueden tratar temas propios de las leyes orgánicas, tributarias o de carácter internacional y necesitan ser respaldadas por, al menos, 500.000 firmas',
            },
          ]}
        >
          <p>
            Se remite la proposición de ley al Congreso. La Mesa lo recibe, ordena su publicación en el Boletín Oficial de las Cortes
            Generales y la remite al Gobierno.
          </p>
        </ModalContent>
        <ModalContent
          title='2. Gobierno: contestación'
          icons={[
            { type: 'quien', title: 'Gobierno' },
            { type: 'que', title: 'Conformidad u oposición a la tramitación' },
            { type: 'cuando', title: '30 días hábiles' },
          ]}
          bannerBottom={[
            {
              color: 'green',
              arrow: 'down',
              title:
                'Si después de 30 días no se ha pronunciado, se entiende que no tiene inconveniente y el texto puede ser incluida en el orden del día del pleno',
            },
          ]}
        >
          <p>
            El Gobierno tiene que dar su visto bueno a la toma en consideración del texto y, si entiende que es negativa porque supone un
            aumento de los gastos o una disminución de los ingresos, tiene capacidad de vetarla.
          </p>
        </ModalContent>
        <ModalContent
          title='3. Pleno: toma en consideración'
          icons={[
            {
              type: 'que',
              title: 'Votación sobre si se tramita o no la iniciativa',
            },
            { type: 'votacion', title: 'Mayoría simple (más síes que noes)' },
          ]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                'Si es una proposición de ley de un parlamento autonómico, las comunidades pueden mandar hasta tres representantes para que la defiendan ante el pleno',
            },
            {
              color: 'grey',
              title:
                'Si es una proposición de ley del Senado, este debate se celebra en la Cámara alta, aunque después su tramitación se produce primero en el Congreso',
            },
          ]}
        >
          <p>
            El pleno del Congreso debate y vota si comienza a tramitar la iniciativa. Para que esto ocurra, será necesaria una mayoría
            simple de apoyos.
          </p>
        </ModalContent>

        <ModalContent
          title='4. Enmiendas'
          icons={[
            { type: 'quien', title: 'Grupos parlamentarios' },
            { type: 'que', title: 'Propuestas de cambios en el texto' },
            {
              type: 'cuando',
              title: '15 días hábiles (prorrogables por la Mesa)',
            },
          ]}
        >
          <p>
            La Mesa remite la iniciativa a la comisión correspondiente y abre el plazo de presentación de enmiendas. Las formaciones pueden
            presentar de dos tipos:
          </p>
          <ul>
            <li>
              <strong>Enmiendas a la totalidad</strong>: en este caso, solo pueden proponer la sustitución por un nuevo texto completo.
            </li>
            <li>
              <strong>Al articulado</strong>: plantean eliminar, cambiar o añadir artículos a la iniciativa.
            </li>
          </ul>
        </ModalContent>

        <ModalContent
          title='5. Pleno: debate de totalidad'
          bannerTop={[
            {
              color: 'red',
              title: 'Si se han presentado enmiendas a la totalidad',
            },
          ]}
          icons={[
            { type: 'que', title: 'Votación sobre las enmiendas de totalidad' },
            { type: 'votacion', title: 'Mayoría simple (más síes que noes)' },
          ]}
          bannerBottom={[
            {
              color: 'green',
              arrow: 'down',
              title: 'Si no hay enmiendas a la totalidad o son rechazadas por la mayoría',
            },
          ]}
        >
          <p>
            Si se han presentado enmiendas a la totalidad, se celebra un pleno donde los diputados se pronuncian sobre la propuesta de
            cambio total del texto. Para ser aprobadas necesitan una mayoría simple de apoyos. Si no los consigue, el proyecto de ley
            continúa su tramitación.
          </p>
        </ModalContent>

        <ModalContent
          title='6. Comisión: ponencia y dictamen'
          icons={[
            {
              type: 'quien',
              title: 'Diputados en representación de los grupos',
            },
            { type: 'que', title: 'Elaboración de un dictamen' },
          ]}
          bannerBottom={[
            {
              color: 'green',
              arrow: 'cornerDown',
              title: 'Si la comisión ejerce la competencia legislativa plena, el proyecto de ley pasa directamente al Senado',
            },
          ]}
        >
          <p>
            La comisión elige entre sus miembros a una ponencia —un grupo de diputados que representa a las formaciones parlamentarias— para
            que elabore un informe con el texto remitido por el Gobierno y las enmiendas acordadas.
          </p>
          <p>
            A continuación, la comisión debate y vota este texto, así como las propuestas que no hayan sido incorporadas. Después, emite un
            dictamen que debe ratificar el pleno de la Cámara.
          </p>
        </ModalContent>
        <ModalContent
          title='7. Pleno: deliberación'
          icons={[
            { type: 'que', title: 'Votación del dictamen y enmiendas ‘vivas’' },
            { type: 'votacion', title: 'Mayoría simple (más síes que noes)' },
          ]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                'Si es una ley orgánica, se produce una votación final en la que hace falta una mayoría absoluta de apoyos (176 síes) para que se apruebe.',
            },
          ]}
        >
          <p>
            El pleno del Congreso se pronuncia sobre el dictamen emitido por la comisión. También, sobre las enmiendas que en la comisión no
            han contado con los apoyos necesarios, pero que los grupos han decidido mantener. El texto necesita más síes que noes para ser
            aprobado.
          </p>
        </ModalContent>

        <ModalContent
          title='8. Senado'
          icons={[
            { type: 'que', title: 'Continuación de la tramitación del texto' },
            { type: 'cuando', title: '2 meses' },
          ]}
        >
          <p>La Cámara alta recibe el proyecto de ley aprobado por el Congreso y lo tramita, pudiendo realizar tres acciones:</p>
          <ul>
            <li>
              <strong>Ratificar el texto</strong> en los mismos términos en los que lo aprobó el Congreso
            </li>
            <ModalBanner type='green'>El proyecto de ley queda aprobado de forma definitiva</ModalBanner>
            <li>
              <strong>Introducir enmiendas</strong> mediante un proceso similar al del Congreso.
            </li>
            <li>
              <strong>Vetar el texto</strong> por mayoría absoluta (la mitad más uno de los senadores).
            </li>
          </ul>
        </ModalContent>

        <ModalContent
          title='9. Pleno: ratificación'
          bannerTop={[
            {
              color: 'red',
              title: 'Solo si el Senado introduce enmiendas o veta el proyecto de ley.',
            },
          ]}
          icons={[{ type: 'que', title: 'Votación de los cambios de la Cámara alta' }]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                ' Si es una ley orgánica, se produce una votación final en la que hace falta una mayoría absoluta de apoyos (176 síes) para que se apruebe.',
            },
          ]}
        >
          <p>
            Si el Senado introduce enmiendas o veta el proyecto de ley, el texto debe volver al Congreso para que ratifique estos cambios o
            levante el veto.
          </p>
          <ul>
            <li>
              <strong>Enmiendas</strong>: puede aprobarlas o rechazarlas por mayoría simple.
            </li>
            <li>
              <strong>Veto</strong>: tiene la posibilidad de levantar este ‘no’ si aprueba por mayoría absoluta la versión aprobada por el
              Congreso. Si no la obtuviera, se volvería a repetir la votación a los dos meses desde que se interpuso el veto. Entonces,
              bastaría con el apoyo de una mayoría simple de los diputados para desbloquearlo.
            </li>
          </ul>
        </ModalContent>
        <ModalContent title='10. Publicación en el BOE '>
          <p>Una vez aprobada, la ley es sancionada por el Rey, promulgada y publicada en el Boletín Oficial del Estado.</p>
        </ModalContent>
      </ModalHeader>
      <ModalHeader
        handleCloseModal={handleCloseModal}
        activeModal={activeModal}
        activeModalValue='realDecretoLey'
        title='Real Decreto Ley'
        titleGenre='m'
      >
        <ModalContent title='1. Remisión al Congreso' icons={[{ type: 'quien', title: 'Gobierno' }]}>
          <p>
            El Consejo de Ministros aprueba un real decreto ley, se publica en el Boletín Oficial del Estado y lo remite al Congreso para
            que vote su convalidación o derogación.
          </p>
        </ModalContent>
        <ModalContent
          title='2. Pleno: convalidación'
          icons={[
            {
              type: 'que',
              title: 'Convalidación o derogación del real decreto ley',
            },
            { type: 'cuando', title: '30 días hábiles desde su promulgación' },
            { type: 'votacion', title: 'Mayoría simple (más síes que noes)' },
          ]}
          bannerBottom={[
            {
              color: 'grey',
              title: 'El real decreto ley inicia su tramitación como proyecto de ley',
            },
          ]}
        >
          <p>
            El Congreso debe votar si convalida el real decreto ley antes de que pasen 30 días hábiles desde su promulgación. Para que sea
            ratificado, necesita el apoyo de la mayoría simple de los diputados.
          </p>
          <ModalBanner type='green' arrowType='down'>
            El real decreto ley se convalida y termina su tramitación
          </ModalBanner>
          <p>
            Una vez convalidado, si los grupos de la oposición quieren introducir cambios en el texto y lo apoya la mayoría de la Cámara, el
            texto se puede tramitar como proyecto de ley por el procedimiento de urgencia. Los plazos quedan, así, reducidos a la mitad
            respecto a la tramitación ordinaria.
          </p>
        </ModalContent>
        <ModalContent
          title='3. Enmiendas'
          icons={[
            { type: 'que', title: 'Propuestas de cambios en el texto' },
            {
              type: 'cuando',
              title: '15 días hábiles (prorrogables por la Mesa)',
            },
          ]}
        >
          <ul>
            Las formaciones pueden presentar enmiendas de dos tipos:
            <li>
              <strong>Enmiendas a la totalidad</strong>: proponen su sustitución por un nuevo texto completo.
            </li>
            <li>
              <strong>Al articulado</strong>: plantean eliminar, cambiar o añadir artículos a la iniciativa.
            </li>
          </ul>
        </ModalContent>
        <ModalContent
          title='4. Pleno: debate de totalidad'
          bannerTop={[
            {
              color: 'red',
              title: ' Si se han presentado enmiendas a la totalidad',
            },
          ]}
          icons={[
            { type: 'que', title: 'Votación sobre las enmiendas de totalidad' },
            { type: 'votacion', title: 'Mayoría simple (más síes que noes)' },
          ]}
          bannerBottom={[
            {
              color: 'green',
              arrow: 'down',
              title: 'Si no hay enmiendas a la totalidad o son rechazadas por la mayoría',
            },
          ]}
        >
          <p>
            Si se han presentado enmiendas a la totalidad, se celebra un pleno donde los diputados se pronuncian sobre la propuesta de
            cambio total del texto. Para ser aprobadas necesitan una mayoría simple de apoyos. Si no los consigue, el proyecto de ley
            continúa su tramitación.
          </p>
        </ModalContent>
        <ModalContent
          title='5. Comisión: ponencia y dictamen'
          icons={[
            {
              type: 'quien',
              title: 'Diputados en representación de los grupos',
            },
            {
              type: 'que',
              title: 'Elaboración de un dictamen',
            },
          ]}
          bannerBottom={[
            {
              color: 'green',
              arrow: 'cornerDown',
              title: 'Si la comisión ejerce la competencia legislativa plena, el proyecto de ley pasa directamente al Senado',
            },
          ]}
        >
          <p>
            La comisión elige entre sus miembros a una ponencia —un grupo de diputados que representa a las formaciones parlamentarias— para
            que elabore un <strong>informe</strong> con el texto remitido por el Gobierno y las enmiendas acordadas.
          </p>
          <p>
            A continuación, la comisión debate y vota este texto, así como las propuestas que no hayan sido incorporadas. Después, emite un
            dictamen que debe ratificar el pleno de la Cámara.
          </p>
        </ModalContent>

        <ModalContent
          title='6. Pleno: deliberación'
          icons={[
            { type: 'que', title: 'Votación del dictamen y enmiendas ‘vivas’' },
            { type: 'votacion', title: 'Mayoría simple (más síes que noes)' },
          ]}
        >
          <p>
            El pleno del Congreso se pronuncia sobre el dictamen emitido por la comisión. También, sobre las enmiendas que en la comisión no
            han contado con los apoyos necesarios, pero que los grupos han decidido mantener. El texto necesita más síes que noes para ser
            aprobado.
          </p>
        </ModalContent>

        <ModalContent
          title='7. Senado'
          icons={[
            { type: 'que', title: 'Continuación de la tramitación del texto' },
            { type: 'cuando', title: '2 meses' },
          ]}
        >
          <p>La Cámara alta recibe el proyecto de ley aprobado por el Congreso y lo tramita, pudiendo realizar tres acciones:</p>
          <ul>
            <li>
              <strong>Ratificar el texto</strong> en los mismos términos en los que lo aprobó el Congreso
            </li>
            <ModalBanner type='green'>El proyecto de ley queda aprobado de forma definitiva</ModalBanner>
            <li>
              <strong>Introducir enmiendas</strong> mediante un proceso similar al del Congreso.
            </li>
            <li>
              <strong>Vetar el texto</strong> por mayoría absoluta (la mitad más uno de los senadores).
            </li>
          </ul>
        </ModalContent>

        <ModalContent
          title='8. Pleno: ratificación '
          bannerTop={[
            {
              color: 'red',
              title: 'Solo si el Senado introduce enmiendas o veta el proyecto de ley',
            },
          ]}
          icons={[
            {
              type: 'que',
              title: 'Votación de las enmiendas o el veto del Senado',
            },
          ]}
        >
          <p>
            Si el Senado introduce enmiendas o veta el proyecto de ley, el texto debe volver al Congreso para que ratifique estos cambios o
            levante el veto.
          </p>
          <ul>
            <li>
              <strong> Enmiendas</strong>: puede aprobarlas o rechazarlas por mayoría simple.
            </li>
            <li>
              <strong> Veto</strong>: tiene la posibilidad de levantar este ‘no’ si aprueba por mayoría absoluta la versión aprobada por el
              Congreso. Si no la obtuviera, se volvería a repetir la votación a los dos meses desde que se interpuso el veto. Entonces,
              bastaría con el apoyo de una mayoría simple de los diputados para desbloquearlo.
            </li>
          </ul>
        </ModalContent>
        <ModalContent title='9. Publicación en el BOE'>
          <p>Una vez aprobada, la ley es sancionada por el Rey, promulgada y publicada en el Boletín Oficial del Estado.</p>
        </ModalContent>
      </ModalHeader>

      <ModalHeader
        handleCloseModal={handleCloseModal}
        activeModal={activeModal}
        activeModalValue='realDecretoLegislativo'
        title='Real Decreto Legislativo'
        titleGenre='m'
      >
        <ModalContent>
          <p>
            Los reales decretos legislativos son normas con rango de ley que dicta el Gobierno por una delegación legislativa que le
            conceden las Cortes Generales.
          </p>
          <ul>
            <li>
              <strong>Ley de bases</strong>: se articulan una serie de medidas en un texto, que en ningún caso podrá autorizar la
              modificación de la propia ley de bases ni dictar normas retroactivas.
            </li>
            <li>
              <strong>Ley ordinaria</strong>: se refunden varios textos legales en uno solo
            </li>
          </ul>
        </ModalContent>
      </ModalHeader>

      <ModalHeader
        handleCloseModal={handleCloseModal}
        activeModal={activeModal}
        activeModalValue='reformaEstatoAutonomía'
        title='Reforma del Estatuto de Autonomía (procedimiento ordinario)'
        titleGenre='f'
      >
        <ModalContent
          bannerTop={[
            {
              color: 'grey',
              title:
                ' A continuación se explica el procedimiento ordinario. Hay comunidades autónomas que contemplan particularidades concretas sobre este proceso.',
            },
          ]}
        ></ModalContent>

        <ModalContent
          title='1. Registro y calificación de la Mesa del Congreso'
          icons={[
            { type: 'quien', title: 'Asambleas autonómicas' },
            {
              type: 'que',
              title: 'Publicación en el Boletín Oficial de las Cortes Generales',
            },
          ]}
          bannerBottom={[
            {
              color: 'red',
              title: 'Si la propuesta no cumple los requisitos, lo comunica a la asamblea autonómica para que los subsane',
            },
          ]}
        >
          <p>
            La Mesa recibe la iniciativa y comprueba si cumple los requisitos constitucionales y estatutarios. Si lo hace, ordena su
            publicación en el Boletín Oficial de las Cortes Generales y la <strong>remite al Gobierno</strong>.
          </p>
        </ModalContent>

        <ModalContent
          title='2. Debate de totalidad'
          icons={[
            { type: 'que', title: 'Votación sobre la reforma planteada' },
            { type: 'votacion', title: 'Mayoría simple (más síes que noes)' },
          ]}
        >
          <p>
            El pleno celebra un debate de totalidad. La asamblea autonómica puede enviar a esta sesión hasta a tres representantes para
            defender la iniciativa. Para que el texto continúe su tramitación necesita el visto bueno de la mayoría de los diputados.
          </p>
        </ModalContent>
        <ModalContent
          title='3. Enmiendas'
          icons={[
            { type: 'quien', title: 'Grupos parlamentarios' },
            { type: 'que', title: 'Propuestas de cambios en el texto' },
            {
              type: 'cuando',
              title: '15 días hábiles (prorrogables por la Mesa)',
            },
          ]}
        >
          <p>
            La Mesa remite la iniciativa a la comisión correspondiente y abre el plazo de presentación de enmiendas. Las formaciones pueden
            presentar <strong>enmiendas al articulado </strong>por las que plantean eliminar, cambiar o añadir artículos a la iniciativa.
          </p>
        </ModalContent>

        <ModalContent
          title='4. Comisión: ponencia y dictamen'
          icons={[
            {
              type: 'quien',
              title: 'Diputados en representación de los grupos',
            },
            { type: 'que', title: 'Elaboración de un dictamen' },
          ]}
        >
          <p>
            La comisión elige entre sus miembros a una ponencia —un grupo de diputados que representa a las formaciones parlamentarias— para
            que elabore un <strong>informe</strong> con el texto remitido por el Gobierno y las enmiendas acordadas.
          </p>
          <p>
            A continuación, la comisión debate y vota este texto, así como las enmiendas que no hayan sido incorporadas, artículo por
            artículo. Después, emite un dictamen que debe ratificar el pleno de la Cámara.
          </p>
        </ModalContent>
        <ModalContent
          title='5. Pleno: deliberación'
          icons={[
            { type: 'que', title: 'Votación del dictamen y enmiendas ‘vivas’' },
            { type: 'quien', title: 'Mayoría simple (más síes que noes)' },
          ]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                'Al ser una ley orgánica, se produce una votación final en la que hace falta una mayoría absoluta de apoyos (176 síes) para que se apruebe.',
            },
          ]}
        >
          <p>
            El pleno del Congreso se pronuncia sobre el dictamen emitido por la comisión. También, sobre las enmiendas que en la comisión no
            han contado con los apoyos necesarios, pero que los grupos han decidido mantener. El texto necesita una mayoría absoluta de
            apoyos para ser aprobado.
          </p>
        </ModalContent>
        <ModalContent
          title='6. Senado'
          icons={[
            { type: 'que', title: 'Continuación de la tramitación del texto' },
            { type: 'cuando', title: '2 meses' },
          ]}
        >
          <p>La Cámara alta recibe el proyecto de ley aprobado por el Congreso y lo tramita, pudiendo realizar tres acciones:</p>
          <ul>
            <li>
              <strong>Ratificar el texto</strong> en los mismos términos en los que lo aprobó el Congreso
            </li>
            <ModalBanner type='green'>El proyecto de ley queda aprobado de forma definitiva</ModalBanner>
            <li>
              <strong>Introducir enmiendas</strong> mediante un proceso similar al del Congreso
            </li>
            <li>
              <strong>Vetar el texto</strong> por mayoría absoluta (la mitad más uno de los senadores).
            </li>
          </ul>
        </ModalContent>

        <ModalContent
          title='7. Pleno: ratificación'
          bannerTop={[
            {
              color: 'red',
              title: 'Solo si el Senado introduce enmiendas o veta el proyecto de ley',
            },
          ]}
          icons={[{ type: 'que', title: 'Votación de los cambios de la Cámara alta' }]}
          bannerBottom={[
            {
              color: 'grey',
              title:
                ' Al ser una ley orgánica, se produce una votación final en la que hace falta una mayoría absoluta de apoyos (176 síes) para que se apruebe.',
            },
          ]}
        >
          <p>
            Si el Senado introduce enmiendas o veta el proyecto de ley, el texto debe volver al Congreso para que ratifique estos cambios o
            levante el veto.
          </p>
          <ul>
            <li>
              <strong>Enmiendas</strong>: puede aprobarlas o rechazarlas por mayoría simple.
            </li>
            <li>
              <strong>Veto</strong>: tiene la posibilidad de levantar este ‘no’ si aprueba por mayoría absoluta la versión aprobada por el
              Congreso. Si no la obtuviera, se volvería a repetir la votación a los dos meses desde que se interpuso el veto. Entonces,
              bastaría con el apoyo de una mayoría simple de los diputados para desbloquearlo.
            </li>
          </ul>
        </ModalContent>
        <ModalContent title='8. Publicación en el BOE'>
          <p>Una vez aprobada, la ley es sancionada por el Rey, promulgada y publicada en el Boletín Oficial del Estado.</p>
        </ModalContent>
      </ModalHeader>

      <ModalHeader
        handleCloseModal={handleCloseModal}
        activeModal={activeModal}
        activeModalValue='reformaConstitucional'
        title='Reforma constitucional'
        titleGenre='f'
      >
        <ModalContent icons={[{ type: 'quien', title: 'Gobierno, Congreso, Senado y CC.AA' }]}>
          <p>El procedimiento es similar al de otras iniciativas, pero con algunas diferencias.</p>
        </ModalContent>
        <ModalContent title='Reforma total o de aspectos esenciales'>
          <p>
            Propone cambiar los principios básicos del Estado, los derechos y las libertades de los ciudadanos y la Corona o el conjunto de
            la Constitución.
          </p>
          <ul>
            <li>
              <strong>Aprobación por mayoría cualificada</strong>: es necesario que apoyen la reforma dos tercios de los diputados (234) y
              de los senadores (177).
            </li>
            <li>
              <strong>Disolución de las Cortes</strong>: se convocan elecciones generales. Las nuevas cámaras vuelven a estudiar y votar el
              texto, que tiene que ser ratificado por una mayoría de dos tercios.
            </li>
            <li>
              <strong>Referéndum popular</strong>: la consulta ciudadana es obligatoria y vinculante.
            </li>
          </ul>
        </ModalContent>
        <ModalContent title='Reforma parcial o de aspectos no esenciales'>
          <p>Hace falta una mayoría de tres quintos en cada Cámara (210 diputados y 159 senadores).</p>
          <p>
            Si no se logra, se crea una
            <strong> comisión con el mismo número de diputados y senadores</strong>, quienes presentan un texto que debe ser votado por las
            Cortes Generales.
          </p>
          <p>
            Si sigue sin lograrse el apoyo de las tres quintas partes, cambian los márgenes necesarios para aprobar la reforma
            constitucional: mayoría absoluta en el Senado (133) y de dos tercios en el Congreso (234).
          </p>
          <p>
            La reforma será sometida a un <strong>referéndum ciudadano</strong> si una décima parte de los diputados (35) o de los senadores
            (27) lo solicitan en los 15 días siguientes a la aprobación.
          </p>
        </ModalContent>
      </ModalHeader>
    </div>
  );
}
