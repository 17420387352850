import { useState, useRef, createRef, useEffect } from 'react';

export default function useNavScroll(props) {
  const { singleIn, handleCloseStagesMenu } = props;

  const stagesLength = singleIn?.stages?.length;
  let titleNavRef = useRef([]);
  const resumen = useRef(null);
  const desktopHeaderHeight = 80;
  const mobileHeaderHeight = 70;
  const desktopFooterHeight = 135;
  const [activeTitleNav, setActiveTitleNav] = useState({
    title: 'Resumen',
    navId: 'Resumen',
  });

  const [card, setCard] = useState(null);

  const checkScrollPosition = refItem => {
    var scrollPos = window.scrollY;
    const visible =
      refItem?.offsetTop - 150 <= scrollPos && refItem?.offsetTop - 150 + refItem.clientHeight - desktopFooterHeight > scrollPos;
    return visible;
  };

  if (titleNavRef.current.length !== stagesLength) {
    titleNavRef.current = Array(stagesLength)
      .fill()
      .map((_, i) => titleNavRef.current[i] || createRef());
  }

  useEffect(() => {
    const handleScroll = () => {
      if (singleIn && titleNavRef?.current) {
        if (resumen?.current?.clientHeight > window.scrollY) {
          if (activeTitleNav.navId !== resumen.current.id) {
            setActiveTitleNav({
              title: 'Resumen',
              navId: resumen.current.id,
            });
          }
        }
        for (let i = 0; i < titleNavRef.current.length; i++) {
          const refElement = titleNavRef.current[i].current;
          if (checkScrollPosition(refElement)) {
            if (activeTitleNav.navId !== refElement.id) {
              const text = refElement.querySelector('h2.c-title').innerText;
              setActiveTitleNav({
                title: text,
                navId: refElement.id,
              });
            }
          }
        }

        // Handle click nav menu stage select
        if (card) {
          setTimeout(() => {
            const refElement = titleNavRef.current.filter(nav => nav.current?.id === card?.id).shift();
            if (refElement && activeTitleNav.navId !== refElement.current?.id) {
              const text = refElement.current.querySelector('h2.c-title').innerText;
              setActiveTitleNav({
                title: text,
                navId: refElement.current.id,
              });
              setCard(null);
            }
          }, 500);
        }

        // Get last stage nav when is scrolled to the bottom
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          const refElement = titleNavRef.current[titleNavRef.current.length - 1].current;
          if (refElement && activeTitleNav.navId !== refElement.id) {
            const text = refElement.querySelector('h2.c-title').innerText;
            setActiveTitleNav({
              title: text,
              navId: refElement.id,
            });
          }
        }
      }
    };
    if (singleIn) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [singleIn, activeTitleNav, card]);

  const handleNavClick = (e, title) => {
    e.preventDefault();
    const currentCard = document.getElementById(title);
    setCard(currentCard);
    let distance;
    if (window.innerWidth >= 767) {
      distance = currentCard?.offsetTop - desktopHeaderHeight;
    } else if (window.innerWidth < 767) {
      handleCloseStagesMenu();
      distance = currentCard?.offsetTop - mobileHeaderHeight;
    }
    window.scrollTo({
      top: distance,
      behavior: 'smooth',
    });
  };

  return {
    handleNavClick,
    activeTitleNav,
    resumen,
    titleNavRef,
  };
}
