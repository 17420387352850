import VoteGraphic from 'components/Vote/VoteGraphic';
import VoteList from 'components/Vote/VoteList';
import React from 'react';

function getVotes(info) {
  var listVotes = { yes: [], no: [], abstention: [], noVote: [] };
  for (const key in info.voteByParliamentaryGroup) {
    if (Object.hasOwnProperty.call(info.voteByParliamentaryGroup, key)) {
      const parlGroup = info.voteByParliamentaryGroup[key];
      const id = parlGroup.parliamentaryGroupId;
      for (const key in parlGroup.voteCount) {
        if (Object.hasOwnProperty.call(parlGroup.voteCount, key)) {
          const number = parlGroup.voteCount[key];
          if (number > 0) listVotes[key].push({ id, number });
        }
      }
    }
  }
  return listVotes;
}
export default function Voting(props) {
  const { info, parliamentaryGroups } = props;
  const votes = getVotes(info);

  const totalVotes = Object.values(info.voteCount).reduce((t, n) => t + n, 0);

  const title = info.subGroupTitle ? `${info.title} (${info.subGroupTitle})` : info.title;

  return (
    <div className='card-single__inner'>
      <div className='b-vote b-vote--alt'>
        <h2 className='c-title c-title--l'>Votación</h2>
        <h3 className='b-vote__title'>{title}</h3>
        <div className='b-vote__graphic-wrapper'>
          <div className='b-vote__graphic-header'>
            <strong className='yes'>Sí: {info.voteCount.yes}</strong>
            <strong className='no'>No: {info.voteCount.no}</strong>
          </div>
          <VoteGraphic info={info} totalVotes={totalVotes} />

          {Object.keys(votes).map((key, i) => (
            <VoteList key={key + '_' + i} name={key} votes={votes[key]} info={info} parliamentaryGroups={parliamentaryGroups} />
          ))}
        </div>
      </div>
    </div>
  );
}
