import React, { useCallback, useEffect, useContext } from 'react';

import useAuthorSearch from 'hooks/Results/useAuthorSearch';

import FilterList from './FilterList';
import { filterByList, sortByList, euDrivenList } from 'utils/dataArray';
import LegislatureContext from 'context/LegislatureContext';

export default function FilterBlockDesktop(props) {
  const { groupTitle, list, arrayOpen, FILTER, handleOpenFilter, handleClickFilter, handleResetFilter, setFiltersList, filtersList } =
    props;

  const { legislaturesList } = useContext(LegislatureContext);

  const check = arrayOpen.find(obj => obj.groupTitle === groupTitle);
  const filterLength = FILTER[groupTitle].length;

  const { isSearching, keyword, handleAuthorInputChange } = useAuthorSearch({
    filtersList,
    setFiltersList,
    FILTER,
  });

  const getName = () => {
    if (groupTitle === filterByList.orderBy) {
      const name = sortByList.find(x => {
        return x.searchName === FILTER[groupTitle][0].name;
      }).name;
      return name;
    }
    if (groupTitle === filterByList.legislature) {
      const name = legislaturesList.find(x => {
        return x.id === FILTER[groupTitle][0].name;
      }).name;
      return name;
    }
    if (groupTitle === filterByList.euDriven) {
      const name = euDrivenList.find(x => {
        return x.data === FILTER[groupTitle][0].name;
      }).name;
      return name;
    }
    return FILTER[groupTitle][0].name;
  };

  const initialFilterByScore = useCallback(() => {
    if (FILTER[filterByList.orderBy].length < 1) {
      FILTER[filterByList.orderBy] = [{ name: sortByList[0].searchName }];
      const score = document.querySelector(`[data-value="${sortByList[0].searchName}"]`);
      if (score) {
        const event = new Event('click');
        score.dispatchEvent(event);
        handleClickFilter(event, filterByList.orderBy);
      }
    }
  }, [FILTER, handleClickFilter]);

  useEffect(() => {
    initialFilterByScore();
  }, [initialFilterByScore]);

  return (
    <div className={`e-filter-dd${check.isOpen ? ' open' : ''}${filterLength >= 1 ? ' active' : ''}`}>
      <span className={`e-filter-dd__open${check.isOpen ? ' open' : ''}`} onClick={e => handleOpenFilter(e, groupTitle)} data-open>
        {filterLength === 1 && !check.isOpen ? getName() : groupTitle}

        <span className='e-filter-dd__filter-count'>{filterLength > 1 && filterLength}</span>
      </span>
      <div className={`e-filter${check.isOpen ? ' show' : ''}`}>
        <FilterList
          list={list}
          FILTER={FILTER}
          keyword={keyword}
          groupTitle={groupTitle}
          isSearching={isSearching}
          filtersList={filtersList}
          setFiltersList={setFiltersList}
          handleClickFilter={handleClickFilter}
          handleAuthorInputChange={handleAuthorInputChange}
          type='desktop'
        />
        <div className='e-filter__submit' onClick={e => handleResetFilter(e, groupTitle)}>
          <button className='e-reset' type='reset'>
            Restablecer
          </button>
        </div>
      </div>
    </div>
  );
}

FilterBlockDesktop.defaultProps = {
  groupTitle: '',
  list: [],
  arrayOpen: [],
  FILTER: {},
};
