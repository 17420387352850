import { useState, useEffect } from 'react';
import getAPI from 'services/getAPI';
export default function useShortResults(props) {
  /*
   *
   * Props
   *
   */

  const { setLegacyResults, URLKeyword } = props;

  /*
   *
   * States
   *
   */

  const [isShortResultsFetched, setIsShortResultsFetched] = useState(false);

  /*
   *
   * Fetch on URLKeyword change
   *
   */
  useEffect(() => {
    const legacyQuery = {
      ...(URLKeyword && { text: URLKeyword }),
      size: '3',
    };

    getAPI({
      url: 'legacyInitiatives',
      query: legacyQuery,
      typeQuery: 'obj',
    })
      .then(res => {
        setLegacyResults(res.response);
        setIsShortResultsFetched(true);
      })

      .catch(err => {
        console.log(err);
      });

    return () => {
      setIsShortResultsFetched(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URLKeyword]);

  return {
    isShortResultsFetched,
  };
}
