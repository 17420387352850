import React, { useEffect, useRef } from 'react';
import { tryDecode } from 'utils/functions';

export default function InsideHTML({ html, ...rest }) {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef(null);

  useEffect(() => {
    if (!html || !divRef.current) throw "html prop cant't be null";

    const slotHtml = document.createRange().createContextualFragment(tryDecode(html));
    divRef.current.innerHTML = '';
    divRef.current.appendChild(slotHtml);
  }, [html, divRef]);

  return <div {...rest} className='story__iframe' ref={divRef}></div>;
}
