import { useState, useEffect } from 'react';
import { listIncludes } from 'utils/functions';
import { filterByList, processingResultAprobada, processingResultConvalidada, processingResultConvalidadaTramitada } from 'utils/dataArray';
function removeItem(el, getter, setter) {
  const newValue = getter;
  const removeIndex = getter
    .map(item => {
      return item.name;
    })
    .indexOf(el.dataset.value);

  newValue.splice(removeIndex, 1);
  setter(newValue);
}

function addItem(el, getter, setter) {
  const newValue = getter;
  const src = el.parentNode?.parentNode?.firstChild?.firstChild?.src;
  const newObj = src ? { name: el.dataset.value, src: src } : { name: el.dataset.value };

  newValue.push(newObj);
  setter(newValue);
}

function addItemStatus(value, getter, setter) {
  const newObj = { name: value };
  if (value) {
    newObj.src = value;
  }

  getter.push(newObj);
  setter(getter);
}

function hasItemStatus(value, itemStatuses) {
  return itemStatuses.filter(filterStatus => filterStatus.name === value).length != 0;
}

function createFilterQueryString(FILTER, FILTER_OPT) {
  let filtersString = '';
  for (const key in FILTER) {
    const arrayFilter = FILTER[key];
    for (let i = 0; i < arrayFilter.length; i++) {
      filtersString = filtersString + '&' + FILTER_OPT[key].title + '=' + arrayFilter[i].name;
    }
  }
  filtersString = encodeURI(filtersString);
  return filtersString;
}

export default function useFilter(props) {
  const { queryFilter, setQueryFilter, setPage } = props;
  /*
   *
   * Set initial filters if found in URLquery
   *
   */
  function getFilterInitialValue(name) {
    let initialValue = [];
    if (queryFilter !== '') {
      const arrayQuery = queryFilter.split(/&/);
      for (const element of arrayQuery) {
        const item = decodeURI(element).split(/=/);
        if (item[0] === name) {
          initialValue.push({ name: item[1] });
        }
      }
    }
    return initialValue;
  }

  /*
   *
   * States
   *
   */
  const [statusFilter, setStatusFilter] = useState(getFilterInitialValue('processingResult') || []);
  const [typeInicFilter, setTypeInicFilter] = useState(getFilterInitialValue('type') || []);
  const [categoryFilter, setCategoryFilter] = useState(getFilterInitialValue('category') || []);
  const [legislatureFilter, setLegislatureFilter] = useState(getFilterInitialValue('legislature') || []);
  const [authorFilter, setAuthorFilter] = useState(getFilterInitialValue('author') || []);
  const [sortByFilter, setSortByFilter] = useState(getFilterInitialValue('sort') || []);
  const [euDrivenFilter, setEuDrivenFilter] = useState(getFilterInitialValue('euDriven') || []);
  const [MBQueueFilter, setMBQueueFilter] = useState([]);

  const [isFiltering, setIsFiltering] = useState(false);

  /*
   *
   * Filter options list
   *
   */
  const FILTER_OPT = {
    [filterByList.category]: {
      getter: categoryFilter,
      setter: value => setCategoryFilter(value),
      title: 'category',
    },
    [filterByList.type]: {
      getter: typeInicFilter,
      setter: value => setTypeInicFilter(value),
      title: 'type',
    },
    [filterByList.processingResult]: {
      getter: statusFilter,
      setter: value => setStatusFilter(value),
      title: 'processingResult',
    },
    [filterByList.author]: {
      getter: authorFilter,
      setter: value => setAuthorFilter(value),
      title: 'author',
    },
    [filterByList.orderBy]: {
      getter: sortByFilter,
      setter: value => setSortByFilter(value),
      title: 'sort',
    },
    [filterByList.legislature]: {
      getter: legislatureFilter,
      setter: value => setLegislatureFilter(value),
      title: 'legislatureId',
    },
    [filterByList.euDriven]: {
      getter: euDrivenFilter,
      setter: value => setEuDrivenFilter(value),
      title: 'euDriven',
    },
  };

  const FILTER = {
    [filterByList.type]: typeInicFilter,
    [filterByList.category]: categoryFilter,
    [filterByList.processingResult]: statusFilter,
    [filterByList.author]: authorFilter,
    [filterByList.orderBy]: sortByFilter,
    [filterByList.legislature]: legislatureFilter,
    [filterByList.euDriven]: euDrivenFilter,
  };
  /*
   *
   * Update mobile filter
   *
   */
  const [updateMB, setUpdateMB] = useState(false);
  useEffect(() => {}, [updateMB]);
  /*
   *
   * Toggle input value to filter arrays on Click
   *
   */
  const handleClickFilter = (e, groupTitle) => {
    e.preventDefault();
    setPage(0);
    const el = e.target;

    // Filters limited to one option selected
    const oneOptionFilter = [filterByList.orderBy, filterByList.legislature, filterByList.euDriven];

    if (oneOptionFilter.includes(groupTitle)) {
      if (window.innerWidth >= 767) {
        FILTER_OPT[groupTitle].setter([{ name: el.dataset.value }]);
      }
      if (window.innerWidth < 767) {
        setUpdateMB(!updateMB);
        setMBQueueFilter([{ name: el.dataset.value }]);
      }
    } else {
      // Filters with no limit to options selected
      // Desktop
      if (window.innerWidth >= 767) {
        setIsFiltering(true);
        if (el.checked && !listIncludes(FILTER_OPT[groupTitle].getter, el.dataset.value)) {
          addItem(el, FILTER_OPT[groupTitle].getter, FILTER_OPT[groupTitle].setter, groupTitle);
        } else {
          removeItem(el, FILTER_OPT[groupTitle].getter, FILTER_OPT[groupTitle].setter, groupTitle);
        }
      }
      // Tablet and mobile
      if (window.innerWidth < 767) {
        setUpdateMB(!updateMB);
        if (el.checked && !listIncludes(MBQueueFilter, el.dataset.value)) {
          addItem(el, MBQueueFilter, setMBQueueFilter, groupTitle);
        } else {
          removeItem(el, MBQueueFilter, setMBQueueFilter, groupTitle);
        }
      }
    }

    if (groupTitle === filterByList.processingResult) {
      const filtersStatus = FILTER_OPT[groupTitle].getter;
      const setFilterStatus = FILTER_OPT[groupTitle].setter;
      if (filtersStatus.filter(filterStatus => filterStatus.name === processingResultAprobada).length != 0) {
        if (!hasItemStatus(processingResultConvalidada, filtersStatus)) {
          addItemStatus(processingResultConvalidada, filtersStatus, setFilterStatus);
        }
        if (!hasItemStatus(processingResultConvalidadaTramitada, filtersStatus)) {
          addItemStatus(processingResultConvalidadaTramitada, filtersStatus, setFilterStatus);
        }
      }
    }
  };

  /*
   *
   * Set query
   *
   */
  useEffect(() => {
    const filtersString = createFilterQueryString(FILTER, FILTER_OPT);
    setQueryFilter(filtersString);
    setIsFiltering(false);
    return () => {
      setQueryFilter('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter, typeInicFilter, statusFilter, authorFilter, sortByFilter, isFiltering, legislatureFilter, euDrivenFilter]);

  /*
   *
   * Desktop handler
   *
   */
  const handleResetFilter = (e, groupTitle) => {
    e.preventDefault();
    FILTER_OPT[groupTitle].setter([]);
    setPage(0);
  };

  /*
   *
   * Mobile handler
   *
   */

  const handleUpdateFilter = (e, groupTitle) => {
    e.preventDefault();
    setIsFiltering(true);
    FILTER_OPT[groupTitle].setter(MBQueueFilter);
    setMBQueueFilter([]);
  };
  const handleResetFilterMB = e => {
    e.preventDefault();
    setMBQueueFilter([]);
    setPage(0);
  };
  const handleResetAllMB = () => {
    setMBQueueFilter([]);
    setAuthorFilter([]);
    setTypeInicFilter([]);
    setCategoryFilter([]);
    setStatusFilter([]);
    setSortByFilter([]);
    setLegislatureFilter([]);
    setEuDrivenFilter([]);
  };
  const handleHighCatClick = e => {
    const el = e.target;
    setIsFiltering(true);
    setPage(0);
    if (el.checked && !listIncludes(categoryFilter, el.dataset.value)) {
      addItem(el, categoryFilter, setCategoryFilter);
    } else {
      removeItem(el, categoryFilter, setCategoryFilter);
    }
  };

  function passFilterList(groupTitle) {
    setMBQueueFilter(FILTER_OPT[groupTitle].getter);
  }

  return {
    handleClickFilter,
    handleResetFilter,
    handleResetFilterMB,
    FILTER,
    handleUpdateFilter,
    MBQueueFilter,
    handleResetAllMB,
    handleHighCatClick,
    passFilterList,
    isFiltering,
    queryFilter,
  };
}
