import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import SearchSuggestions from 'components/Home/SearchSuggestions';

import useFocus from 'hooks/Home/useFocus';
import useSuggestions from 'hooks/Home/useSuggestions';

function SearchBar() {
  /*
   *
   * State and hooks
   *
   */
  const history = useHistory();
  const [openTab, setOpenTab] = useState('title');
  const wrapperRef = useRef(null);
  const { isFocus, handleMobileBack, handleMobileFocus } = useFocus({
    ref: wrapperRef,
  });
  const searchInput = useRef(null);
  const [keyword, setKeyword] = useState('');
  const { suggestion, highlightedSuggs, topThree, isSuggFetched, isSearching } = useSuggestions({ keyword, openTab });

  /*
   *
   * Handlers
   *
   */
  const handleSearchBarSubmit = (e, keyword) => {
    e.preventDefault();
    const encKeyword = encodeURIComponent(keyword);
    history.push(`/leyes/text=${encKeyword}`);
  };

  const handleChange = e => {
    setKeyword(e.target.value);
  };
  const handleClearSearch = () => {
    setKeyword('');
  };
  const handleSelectTab = e => {
    setOpenTab(e.currentTarget.dataset.tab);
  };

  /*
   *
   * Tabs
   *
   */
  const tabs = isSuggFetched
    ? [
        {
          title: 'Leyes',
          type: 'title',
          array: suggestion,
          highlighted: true,
        },
        {
          title: 'Autores',
          type: 'author',
          array: suggestion,
        },
        {
          title: 'Categorías',
          type: 'category',
          array: suggestion,
        },
        {
          title: 'Todo',
          type: 'all',
          array: topThree,
        },
      ]
    : [];

  return (
    <form className={`b-search__form${isFocus ? ' active' : ''}`} onSubmit={e => handleSearchBarSubmit(e, keyword)} ref={wrapperRef}>
      <div className={`b-search__input-wrapper${isFocus ? ' active' : ''}`}>
        <span id='searchMobileBack' onClick={handleMobileBack}></span>
        <input
          ref={searchInput}
          type='text'
          placeholder='Ley de educación, ley del solo sí es sí, ley de vivienda...'
          value={keyword}
          onChange={handleChange}
          onFocus={handleMobileFocus}
        />
        {keyword?.length >= 1 && <span id='clearSearch' onClick={handleClearSearch}></span>}
      </div>

      <SearchSuggestions
        tabs={tabs}
        isSuggFetched={isSuggFetched}
        isSearching={isSearching}
        openTab={openTab}
        handleSelectTab={handleSelectTab}
        highlightedSuggs={highlightedSuggs}
        keyword={keyword}
      />
    </form>
  );
}

export default SearchBar;
