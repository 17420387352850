import React from 'react';

function LiteSearchBar(props) {
  const { keyword, handleChange, handleLiteSearchSubmit, handleClearSearchClick } = props;
  return (
    <section className='b-search-lite'>
      <div className='container'>
        <form
          onSubmit={e => {
            handleLiteSearchSubmit(e, keyword);
          }}
        >
          <div className='b-search-lite__input-wrapper'>
            <input id='liteSearchBarInput' type='text' value={keyword} placeholder='"Proyecto de ley"' onChange={handleChange} />
            <span id='clearSearch' onClick={handleClearSearchClick}></span>
          </div>
        </form>
      </div>
    </section>
  );
}

export default LiteSearchBar;
