import React from 'react';

export default function StagesMenuMB(props) {
  const { activeTitleNav, isStagesMenuOpen, handleStagesMenuClick, handleGoBackClick } = props;
  return (
    <div className='b-single__header visible-xs'>
      <div className='container'>
        <button className='e-button-back' onClick={handleGoBackClick}>
          <span id='singleSection'>{activeTitleNav.title}</span>
        </button>
        <button className={`e-burger__wrapper--alt${isStagesMenuOpen ? ' btn-close' : ''}`} onClick={handleStagesMenuClick}>
          <span className='e-burger'></span>
          <span className='e-burger'></span>
          <span className='e-burger'></span>
        </button>
      </div>
    </div>
  );
}
