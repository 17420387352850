import React from 'react';

export default function TitleNav(props) {
  const { title, activeTitleNav, handleNavClick, navId } = props;
  return (
    <li>
      <a className={`${activeTitleNav.navId === navId ? 'active' : ''}`} href={`#${navId}`} onClick={e => handleNavClick(e, navId)}>
        {title}
      </a>
    </li>
  );
}
