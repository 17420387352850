import React from 'react';

export default function PageNumber(props) {
  const { number, handleClick, active = false, type = 'number' } = props;

  const OPTIONS = {
    number: number,
    prev: (
      <svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M9 14.59L7.64331 16L0 8L7.64331 0L8.99045 1.41L2.88327 8L9 14.59Z' fill='#666666' />
      </svg>
    ),
    next: (
      <svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M9 14.59L7.64331 16L0 8L7.64331 0L8.99045 1.41L2.88327 8L9 14.59Z' fill='#666666' />
      </svg>
    ),
    dots: '...',
  };

  return (
    <li key={`page-item_${number}`} className={`page-item${active ? ' active' : ''}`}>
      <button className={`page-link${type === 'prev' || type === 'next' ? ` ${type}` : ''}`} onClick={handleClick}>
        {OPTIONS[type]}
      </button>
    </li>
  );
}
