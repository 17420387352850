import React from 'react';

export default function NoResultsMsg() {
  return (
    <div className='container'>
      <div className='b-list__no-results'>
        <h3 className='c-title c-title--xxxl'>No hay resultados</h3>
        <div className='c-content'>
          <p>Parece que no hemos encontrado nada que coincida con tu búsqueda.</p>
        </div>
      </div>
    </div>
  );
}
