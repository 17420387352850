import StaticContext from 'context/StaticContext';
import React, { useContext } from 'react';

function IconCat(props) {
  const { name } = props;
  const { categoriesList } = useContext(StaticContext);
  const index = categoriesList.findIndex(item => item.name === name);

  const ICON_DEFAULT = { src: '', name: '', shortName: '' };

  const icon = categoriesList[index] || ICON_DEFAULT;

  if (!icon.src) return null;

  return <img src={icon.src} alt={icon.name} />;
}

export default IconCat;
