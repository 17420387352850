import React, { useEffect, useRef, useState, useMemo } from 'react';
import StoryContainer from './StoryContainer';
import logo from 'img/content/logo_parlamentia.svg';
import closeLogo from 'img/content/ico_close.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function Stories(props) {
  const { isFetched, isStoryFetched, source, singleIn, urlID, legislatureSlug, isPreview } = props;
  const history = useHistory();
  const location = useLocation();
  const isIframe = window.location.pathname.includes('/embed/');
  const slides = useMemo(() => {
    return isIframe ? source?.slides?.filter(slide => slide?.kind !== 'related') ?? [] : source?.slides ? source?.slides : [];
  }, [isIframe, source?.slides]);
  const [selected, setSelected] = useState(0);
  const listRef = useRef(null);
  const navBarRef = useRef(null);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);
  const [sharedSlide, setSharedSlide] = useState(null);
  const relatedInfo = isIframe ? source?.slides?.filter(slide => slide?.kind === 'related') : source?.related;

  useEffect(() => {
    const sizeChanged = () => {
      if (listRef.current && rightButtonRef.current && leftButtonRef.current) {
        const navRect = navBarRef.current.getBoundingClientRect();
        const distanceFromTop =
          (window.innerHeight * 17) / 100 < navRect.bottom + 30 ? navRect.bottom + 30 : (window.innerHeight * 17) / 100;
        const distanceButtonsFromTop = listRef.current.offsetHeight / 2;
        listRef.current.style.top = distanceFromTop + 'px';
        rightButtonRef.current.style.top = distanceButtonsFromTop + 'px';
        leftButtonRef.current.style.top = distanceButtonsFromTop + 'px';
        listRef.current.style.left = `${(window.innerWidth * 50) / 100 - listRef.current.offsetWidth / slides.length / 2}px`;
      }
    };
    window.addEventListener('resize', sizeChanged);
    return () => window.removeEventListener('resize', sizeChanged);
  }, [selected, listRef, rightButtonRef, leftButtonRef, navBarRef, slides.length]);

  useEffect(() => {
    if (listRef.current && rightButtonRef.current && leftButtonRef.current) {
      const navRect = navBarRef.current.getBoundingClientRect();
      const distanceFromTop = (window.innerHeight * 17) / 100 < navRect.bottom + 30 ? navRect.bottom + 30 : (window.innerHeight * 17) / 100;
      const distanceButtonsFromTop = listRef.current.offsetHeight / 2;
      listRef.current.style.top = distanceFromTop + 'px';
      rightButtonRef.current.style.top = distanceButtonsFromTop + 'px';
      leftButtonRef.current.style.top = distanceButtonsFromTop + 'px';
      listRef.current.style.left = `${(window.innerWidth * 50) / 100 - listRef.current.offsetWidth / slides.length / 2}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef.current, leftButtonRef, rightButtonRef, slides.length]);

  useEffect(() => {
    if (listRef.current && rightButtonRef.current && leftButtonRef.current) {
      const marginBetweenSlides = listRef.current.offsetWidth / slides.length;
      const buttonWidth = rightButtonRef.current.offsetWidth;
      const distance = window.innerWidth < 450 ? 0 : marginBetweenSlides + buttonWidth;
      listRef.current.style.transition = `0.3s all ease`;
      listRef.current.style.transform = `translate(-${distance * selected}px,0)`;
      const distanceButtonsFromTop = listRef.current.offsetHeight / 2;
      rightButtonRef.current.style.top = distanceButtonsFromTop + 'px';
      leftButtonRef.current.style.top = distanceButtonsFromTop + 'px';
      listRef.current.style.zIndex = 1;
    }
  }, [selected, listRef, leftButtonRef, rightButtonRef, slides.length]);

  useEffect(() => {
    if (
      !isPreview &&
      ((selected === slides.length && slides?.length) || (typeof isStoryFetched === 'boolean' && !isStoryFetched && !isFetched))
    ) {
      history.push('/' + legislatureSlug + '/ley/' + urlID);
    }
  }, [selected, props, slides.length, history, source, isStoryFetched, isFetched, urlID, legislatureSlug, isPreview]);

  const handleGoBackClick = () => {
    pathBack && pathBack !== '' ? history.push(pathBack) : history.goBack();
  };

  /*
   *
   * Override path where goes back button
   *
   */
  const [pathBack, setPathBack] = useState('');
  useEffect(() => {
    history.action === 'POP' ? setPathBack('/buscador-de-leyes') : setPathBack('');
  }, [pathBack, history]);

  return (
    <>
      <Helmet>
        <link rel='canonical' href={`/${legislatureSlug}/ley/${urlID}`} />
      </Helmet>
      {(isFetched || isPreview) && slides && (
        <div className='stories'>
          <div className='stories__bg'></div>
          <div className='stories__nav' ref={navBarRef}>
            <NavLink exact to='/buscador-de-leyes' className='stories__nav--logo'>
              <img src={logo} alt='Parlamentia' />
            </NavLink>
            {location?.fromParlamentia ? (
              <a className={`stories__nav--close ${isIframe ? 'hidden' : ''}`} onClick={handleGoBackClick}>
                <img src={closeLogo} alt='Botón para cerrar historias' />
              </a>
            ) : (
              <NavLink exact to='/buscador-de-leyes' className={`stories__nav--close ${isIframe ? 'hidden' : ''}`}>
                <img src={closeLogo} alt='Botón para cerrar historias' />
              </NavLink>
            )}
          </div>
          <ul className='stories__list' ref={listRef}>
            {slides.length &&
              slides.map((slide, i) => (
                <StoryContainer
                  slides={slides}
                  path={singleIn}
                  selected={selected}
                  i={i}
                  setSelected={setSelected}
                  key={i}
                  ref={{ leftButtonRef, rightButtonRef }}
                  sharedSlide={sharedSlide}
                  setSharedSlide={setSharedSlide}
                  relatedInfo={relatedInfo}
                  legislatureSlug={legislatureSlug}
                  pathBack={pathBack}
                />
              ))}
          </ul>
        </div>
      )}
    </>
  );
}
