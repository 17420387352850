import { useState, useEffect } from 'react';

export default function useTweets(props) {
  const { tweets } = props;
  const tweetsArr = new Array(tweets.length).fill(false);
  const [isTweetLoaded, setIsTweetLoaded] = useState(tweetsArr);
  useEffect(() => {
    window.twttr.ready(() => {
      for (let i = 0; i < isTweetLoaded.length; i++) {
        const idWithParams = String(tweets[i].substring(tweets[i].lastIndexOf('/') + 1));
        const id = idWithParams.split('?')[0];
        const ref = document.getElementById(idWithParams);
        if (!isTweetLoaded[i]) {
          window.twttr.widgets
            .createTweet(id, ref, {
              conversation: 'none',
            })
            .then(() => {
              var newTweets = isTweetLoaded;
              newTweets[i] = true;
              setIsTweetLoaded(newTweets);
            });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tweets]);
}
