import React from 'react';
import autorDefault from 'img/fake/_autor.svg';
export default function AuthorImg(props) {
  const { src, name } = props;

  const img = () => {
    if (src !== null && src !== undefined) {
      return <img src={src} alt={name} />;
    } else {
      return <img src={autorDefault} alt='Autor' />;
    }
  };

  return <i className='e-author__portrait'>{img()}</i>;
}
