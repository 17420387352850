import React from 'react';

export default function AuthorSearchBar(props) {
  const { handleAuthorInputChange, keyword } = props;

  return (
    <div className='filter-input__wrapper'>
      <input className='filter-input' type='text' placeholder='Buscar por nombre' onChange={handleAuthorInputChange} value={keyword} />
    </div>
  );
}
