import React from 'react';
import { Helmet } from 'react-helmet';
import _header from 'img/backgrounds/_header.jpeg';
export const SEO = ({ title, url, type, description, img, card, creator }) => {
  const newUrl = new URL(url);
  const newUrlPathname = newUrl.pathname;
  const finalUrl = window._env_.REACT_APP_HOMEPAGE + newUrlPathname;
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'url',
          content: finalUrl,
        },
        {
          property: 'og:url',
          content: finalUrl,
        },
        {
          property: 'og:type',
          content: type,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:image',
          content: img,
        },
        {
          property: 'twitter:card',
          content: card,
        },
        {
          property: 'twitter:creator',
          content: creator,
        },
        {
          property: 'twitter:title',
          content: title,
        },
        {
          property: 'twitter:description',
          content: description,
        },
        {
          property: 'twitter:image',
          content: img,
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  title: 'Parlamentia - Newtral',
  url: window._env_.REACT_APP_HOMEPAGE,
  type: 'website',
  description: '',
  img: window._env_.REACT_APP_HOMEPAGE + _header,
  card: 'summary_large_image',
  creator: '',
};
