import React from 'react';

export default function ProgressBar(props) {
  const { slides, selected } = props;
  return (
    <div className='progress-bar'>
      {slides.map((_, i) => {
        const emptyOrFilled = selected + 1 > i ? 'unit-filled' : 'unit-empty';
        return (
          <div className='progress-bar__container' key={i}>
            <div className={'progress-bar__container--unit ' + emptyOrFilled}></div>
          </div>
        );
      })}
    </div>
  );
}
