import React from 'react';

export default function VoteGraphic(props) {
  const { info, totalVotes } = props;
  return (
    <div className='b-vote__graphic'>
      <div className='si' style={{ width: (info.voteCount.yes * 100) / totalVotes + '%' }}></div>
      <div
        className='abs'
        style={{
          width: (info.voteCount.abstention * 100) / totalVotes + '%',
        }}
      ></div>
      <div
        className='noVote'
        style={{
          width: (info.voteCount.noVote * 100) / totalVotes + '%',
        }}
      ></div>
      <div className='no' style={{ width: (info.voteCount.no * 100) / totalVotes + '%' }}></div>
    </div>
  );
}
