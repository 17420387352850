import React from 'react';
import Spinner from 'elements/Spinner';
import FilterListItem from 'elements/Results/FilterListItem';
import AuthorFilterSearch from 'elements/Results/AuthorSearchBar';
import { filterByList } from 'utils/dataArray';

export default function FilterList(props) {
  const {
    type,
    list,
    FILTER,
    keyword,
    groupTitle,
    filtersList,
    isSearching,
    setFiltersList,
    handleClickFilter,
    handleAuthorInputChange,
    MBQueueFilter,
    isInnerOpen,
  } = props;
  return (
    <>
      {groupTitle === filterByList.author && (
        <AuthorFilterSearch
          filtersList={filtersList}
          setFiltersList={setFiltersList}
          FILTER={FILTER}
          handleAuthorInputChange={handleAuthorInputChange}
          keyword={keyword}
        />
      )}

      <div className='e-filter-wrapper'>
        <dl className='e-filter__group'>
          {/* {<dt>Diputados</dt>} */}
          {list &&
            !isSearching &&
            list.map(eachOne => {
              return (
                <FilterListItem
                  key={type + '_' + eachOne.name}
                  id={eachOne.id}
                  name={eachOne.name}
                  data={eachOne.data}
                  searchName={eachOne.searchName}
                  src={eachOne.src && eachOne.src}
                  groupTitle={groupTitle}
                  isPerson={eachOne.isPerson && eachOne.isPerson}
                  handleClickFilter={handleClickFilter}
                  FILTER={FILTER}
                  MBQueueFilter={MBQueueFilter}
                  isInnerOpen={isInnerOpen}
                />
              );
            })}
          {isSearching && <Spinner color='white' />}
        </dl>
      </div>
    </>
  );
}
