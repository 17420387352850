import React from 'react';
import ico_document from 'img/content/ico_document.svg';
import Author from 'elements/Author';
function reverseDate(date) {
  return date.split('-').reverse().join('/');
}
export default function Document(props) {
  const { url, date, title } = props;
  const documentInfo = {
    authors: [{ name: title, image: { url: ico_document } }],
  };
  return (
    <a href={url && url} target='_blank' rel='noreferrer'>
      <Author path={documentInfo} subtitle={reverseDate(date)} />
    </a>
  );
}
