import React from 'react';

import Search from 'components/Home/Search';
import SearchHeader from 'components/Home/SearchHeader';

import useBodyClass from 'hooks/useBodyClass';

function Home(props) {
  const { handleOpenModal, handleIsBurguerOpen, numberIn } = props;
  useBodyClass('search-focus', handleIsBurguerOpen);

  return (
    <>
      <div id='content' className='page-content'>
        <SearchHeader numberIn={numberIn}></SearchHeader>
        <Search handleOpenModal={handleOpenModal} />
      </div>
    </>
  );
}

export default Home;
