import React from 'react';
import SearchItem from 'elements/Home/SearchItem';
import Spinner from '../../elements/Spinner';

export default function SearchTabContent(props) {
  const { array = [], type, openTab, handleSelectTab, isSearching, isSuggFetched, highlightedSuggs = [], keyword } = props;

  return (
    <div className={`b-search__tab--content${openTab === type ? ' active' : ''}`} data-tab-content={type} onClick={handleSelectTab}>
      {keyword.length <= 2 && type === 'title' && <p className='c-title c-title--xl b-search__tab--starred'>Lo más destacado</p>}

      <ul className='b-search__results--list'>
        {keyword.length > 2 ? (
          isSearching && isSuggFetched ? (
            <Spinner color='grey' />
          ) : (
            Array.isArray(array) &&
            array.length > 0 &&
            array.map((eachOne, i) => <SearchItem key={type + '_' + array[i]._id} id={array[i]._id} info={array[i]._source}></SearchItem>)
          )
        ) : (
          Array.isArray(highlightedSuggs) &&
          highlightedSuggs.length > 0 &&
          type === 'title' &&
          highlightedSuggs.map((eachOne, i) => (
            <SearchItem
              key={'highlighted-suggestions_' + highlightedSuggs[i]._id}
              id={highlightedSuggs[i]._id}
              info={highlightedSuggs[i]._source}
            ></SearchItem>
          ))
        )}
      </ul>
    </div>
  );
}
